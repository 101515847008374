import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Download } from 'mdi-material-ui';

import { demandEquipmentExportUrl, demandExportUrl } from '../../actions/DemandActions';
import { Secured } from '../utils';
import { paginationToParams } from '../../utils/paging';
import roles from '../../constants/roles';
import { downloadUrl } from '../../actions/DownloadActions';

const styles = (theme) => ({
  buttonLabel: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

class DemandsExport extends Component {
  render() {
    const { pagination, dispatch, classes } = this.props;
    const params = paginationToParams(pagination);
    delete params.page;
    delete params.size;

    const urlParam = params.filter
      ? '?' + params.filter.map((filter) => `filter%5B%5D=${encodeURIComponent(filter)}`).join('&')
      : '';

    const demandsUrl = demandExportUrl + urlParam;
    const equipmentsUrl = demandEquipmentExportUrl + urlParam;

    return (
      !!pagination.totalElements && (
        <>
          <Button onClick={() => dispatch(downloadUrl(demandsUrl, 'Demandes.csv'))}>
            <Download />
            <span className={classes.buttonLabel}>Exporter les demandes</span>
          </Button>
          <Secured requiredRole={roles.demand.equipmentExport.code}>
            <Button onClick={() => dispatch(downloadUrl(equipmentsUrl, 'Matériels Demandes.csv'))}>
              <Download />
              <span className={classes.buttonLabel}>Exporter les matériels</span>
            </Button>
          </Secured>
        </>
      )
    );
  }
}

const stateToProps = ({ demands: { pagination } }) => ({
  pagination,
});

export default compose(withStyles(styles), connect(stateToProps))(DemandsExport);

import roles from './roles';
import { getByIdDecorator } from './AppConstants';
import { BookOpenBlankVariant, Check, Close } from 'mdi-material-ui';
import React from 'react';

const fddTransitions = getByIdDecorator([
  {
    // Dummy transition, automatically created on FDD creation
    id: 'CREATE',
    name: 'Création',
    from: [],
    role: roles.fdd.edit,
  },
  {
    id: 'ASK_VALIDATION',
    name: 'Demande de validation',
    from: ['_00_DRAFT'],
    buttonLabel: 'Demander la validation',
    role: roles.fdd.edit,
    color: 'primary',
    message: () => 'Souhaitez-vous demander la validation Zone de vente du dossier ?',
    confirmationMessage: 'Le dossier FDD a été envoyé pour validation',
    requiresCorrectPayments: true,
    icon: <Check />,
  },
  {
    id: 'REJECT_DA',
    name: 'Rejet MDZ',
    from: ['_10_AWAITING_DA_VALIDATION'],
    buttonLabel: 'Rejeter',
    mandatoryComment: true,
    role: roles.fdd.validate_da,
    color: 'secondary',
    message: (number) => `Souhaitez-vous rejeter le dossier n° ${number} ?`,
    buttons: { confirm: 'Rejeter', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été rejeté',
    icon: <Close />,
  },
  {
    id: 'VALIDATE_DA',
    name: 'Validation MDZ',
    from: ['_10_AWAITING_DA_VALIDATION'],
    buttonLabel: 'Valider',
    role: roles.fdd.validate_da,
    color: 'primary',
    message: (number) => `Souhaitez-vous valider le dossier n° ${number} ?`,
    buttons: { confirm: 'Valider', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été validé',
    requiresCorrectPayments: true,
    icon: <Check />,
  },
  {
    id: 'REJECT_BD',
    from: ['_11_AWAITING_BD_VALIDATION'],
    name: 'Rejet RDD',
    buttonLabel: 'Rejeter',
    mandatoryComment: true,
    role: roles.fdd.validate_bd,
    color: 'secondary',
    message: (number) => `Souhaitez-vous rejeter le dossier n° ${number} ?`,
    buttons: { confirm: 'Rejeter', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été rejeté',
    icon: <Close />,
  },
  {
    id: 'VALIDATE_BD',
    name: 'Validation RDD',
    from: ['_11_AWAITING_BD_VALIDATION'],
    buttonLabel: 'Valider',
    role: roles.fdd.validate_bd,
    color: 'primary',
    message: (number) => `Souhaitez-vous valider le dossier n° ${number} ?`,
    buttons: { confirm: 'Valider', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été validé',
    requiresCorrectPayments: true,
    icon: <Check />,
  },
  {
    id: 'REJECT_DAF',
    from: ['_12_AWAITING_DAF_VALIDATION'],
    name: 'Rejet DAF',
    buttonLabel: 'Rejeter',
    mandatoryComment: true,
    role: roles.fdd.validate_daf,
    color: 'secondary',
    message: (number) => `Souhaitez-vous rejeter le dossier n° ${number} ?`,
    buttons: { confirm: 'Rejeter', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été rejeté',
    icon: <Close />,
  },
  {
    id: 'VALIDATE_DAF',
    name: 'Validation DAF',
    from: ['_12_AWAITING_DAF_VALIDATION'],
    buttonLabel: 'Valider',
    role: roles.fdd.validate_daf,
    color: 'primary',
    message: (number) => `Souhaitez-vous valider le dossier n° ${number} ?`,
    buttons: { confirm: 'Valider', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été validé',
    requiresCorrectPayments: true,
    icon: <Check />,
  },
  {
    id: 'REJECT_DR',
    from: ['_13_AWAITING_DR_VALIDATION'],
    name: 'Rejet DR',
    buttonLabel: 'Rejeter',
    mandatoryComment: true,
    role: roles.fdd.validate_dr,
    color: 'secondary',
    message: (number) => `Souhaitez-vous rejeter le dossier n° ${number} ?`,
    buttons: { confirm: 'Rejeter', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été rejeté',
    icon: <Close />,
  },
  {
    id: 'VALIDATE_DR',
    name: 'Validation DR',
    from: ['_13_AWAITING_DR_VALIDATION'],
    buttonLabel: 'Valider',
    role: roles.fdd.validate_dr,
    color: 'primary',
    message: (number) => `Souhaitez-vous valider le dossier n° ${number} ?`,
    buttons: { confirm: 'Valider', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été validé',
    requiresCorrectPayments: true,
    icon: <Check />,
  },
  {
    id: 'REJECT_CI',
    from: ['_14_AWAITING_CI_VALIDATION'],
    name: 'Rejet CI',
    buttonLabel: 'Rejeter',
    mandatoryComment: true,
    role: roles.fdd.validate_ci,
    color: 'secondary',
    message: (number) => `Souhaitez-vous rejeter le dossier n° ${number} ?`,
    buttons: { confirm: 'Rejeter', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été rejeté',
    icon: <Close />,
  },
  {
    id: 'VALIDATE_CI',
    name: 'Validation CI',
    from: ['_14_AWAITING_CI_VALIDATION'],
    buttonLabel: 'Valider',
    role: roles.fdd.validate_ci,
    color: 'primary',
    message: (number) => `Souhaitez-vous valider le dossier n° ${number} ?`,
    buttons: { confirm: 'Valider', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été validé',
    requiresCorrectPayments: true,
    icon: <Check />,
  },
  {
    id: 'CANCEL',
    name: 'Annulation',
    from: [
      '_00_DRAFT',
      '_10_AWAITING_DA_VALIDATION',
      '_11_AWAITING_BD_VALIDATION',
      '_12_AWAITING_DAF_VALIDATION',
      '_13_AWAITING_DR_VALIDATION',
      '_14_AWAITING_CI_VALIDATION',
      '_20_IN_PROGRESS',
    ],
    buttonLabel: 'Annuler le dossier',
    mandatoryComment: true,
    role: roles.fdd.delete,
    color: 'secondary',
    left: true,
    message: (number) => `Souhaitez-vous annuler le dossier n°${number} ?`,
    confirmationMessage: 'Le dossier FDD a été annulé',
    icon: <Close />,
  },
  {
    // Transition automatically created when validating the last payment, and displayed in the history.
    id: 'CLOSE',
    name: 'Clôture',
    from: [], // Never allow manually creating this transition
    role: roles.fdd.edit,
  },
  {
    id: 'REOPEN',
    name: 'Réouverture',
    from: ['_20_IN_PROGRESS'],
    buttonLabel: 'Rouvrir le dossier',
    mandatoryComment: true,
    role: roles.fdd.reopen,
    color: 'neutral',
    left: true,
    message: (number) => `Souhaitez-vous rouvrir le dossier n° ${number} ?`,
    buttons: { confirm: 'Rouvrir', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été rouvert',
    icon: <BookOpenBlankVariant />,
  },
  {
    id: 'REOPEN_CLOSED',
    name: 'Réouverture après clôture',
    from: ['_30_CLOSED'],
    buttonLabel: 'Rouvrir le dossier',
    mandatoryComment: true,
    role: roles.fdd.reopenClosed,
    color: 'neutral',
    left: true,
    message: (number) => `Souhaitez-vous rouvrir le dossier n° ${number} ?`,
    buttons: { confirm: 'Rouvrir', cancel: 'Annuler' },
    confirmationMessage: 'Le dossier FDD a été rouvert',
    icon: <BookOpenBlankVariant />,
  },
]);

export default fddTransitions;

import { useEffect } from 'react';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { loadStockEquipment, refreshStockEquipment } from '../../../actions/StockEquipmentActions';
import StockEquipmentTable from '../../stock-equipments/StockEquipmentTable';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { hasRole, isSubcontractor } from '../../../services/SecurityService';
import { Alert, Box, Button, Card } from '@mui/material';
import { CardHeader, FormActions } from '../../utils';
import StockEquipmentExport from '../../stock-equipments/StockEquipmentExport';
import { HomeExportOutline, HomeImportOutline, PauseCircle, ReceiptTextEdit, Sync } from 'mdi-material-ui';
import { formatDateTime } from '../../../utils/date-utils';

const StockEquipmentPage = () => {
  const dispatch = useAppDispatch();
  const isUserSubcontractor = useAppSelector(({ currentUser }) => isSubcontractor(currentUser));
  const canEditStocks = useAppSelector(({ currentUser }) => hasRole(currentUser, roles.stockEquipments.edit.code));

  const { stockOutletCode } = useParams();
  const { stockOutletName, pausedInventoryDate } = useAppSelector(
    ({ stockEquipments: { stockOutletName, pausedInventoryDate } }) => ({
      stockOutletName,
      pausedInventoryDate,
    }),
  );

  useEffect(() => {
    dispatch(loadStockEquipment(stockOutletCode || ''));
  }, [dispatch, stockOutletCode]);

  const refreshStock = () => {
    dispatch(refreshStockEquipment(stockOutletCode));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {pausedInventoryDate && (
        <Alert color="warning" icon={<PauseCircle />}>
          Inventaire mis en pause le {formatDateTime(pausedInventoryDate)}
        </Alert>
      )}
      <Card>
        {canEditStocks && isUserSubcontractor && (
          <FormActions
            leftActions={
              <>
                <Button
                  variant="contained"
                  color={pausedInventoryDate ? 'warning' : 'primary'}
                  component={RouterLink}
                  to={`/stock-equipments/${stockOutletCode}/inventory`}
                  startIcon={<ReceiptTextEdit />}
                >
                  {pausedInventoryDate ? "Poursuivre l'inventaire" : "Saisie d'inventaire"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/stock-equipments/${stockOutletCode}/reception`}
                  startIcon={<HomeImportOutline />}
                >
                  Réception de matériel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/stock-equipments/${stockOutletCode}/removal`}
                  startIcon={<HomeExportOutline />}
                >
                  Sortie de matériel
                </Button>
              </>
            }
          />
        )}
        <CardHeader
          actions={
            <>
              <Button variant="text" color="info" onClick={() => refreshStock()} startIcon={<Sync />}>
                Mettre à jour le stock
              </Button>
              <StockEquipmentExport stockOutletCodes={[stockOutletCode]} />
            </>
          }
        >
          Stocks de matériels {stockOutletName}
        </CardHeader>
        <StockEquipmentTable />
      </Card>
    </Box>
  );
};

export default securedPage(roles.stockEquipments.view.code)(StockEquipmentPage);

import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { DrawingCanvas } from '../utils';
import { saveOutletSignature, saveSubcontractorSignature } from '../../actions/InterventionSignaturesActions';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';
import { INTERVENTION_STATUSES } from '../../constants/AppConstants';
import { hasCompletedOperations, hasMissingSerialNumbers } from '../../utils/operation-utils';
import { Attachment } from '../../model/model';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Pen } from 'mdi-material-ui';

const useStyles = makeStyles((theme) => ({
  signature: {
    minHeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signatureImage: {
    maxWidth: '100%',
  },
  colBorder: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ratingDisplay: {
    textAlign: 'center',
  },
}));

const attachmentUrl = (attachment) => {
  if (!attachment || !attachment.id) {
    return null;
  }
  return `/api/attachments/${attachment.id}/${attachment.name}`;
};

type InterventionSignatureProps = {
  interventionId: string;
  interventionStatus: string;
  interventionRating: number;
  addOperations: any[];
  removeOperations: any[];
  moveOperations: any[];
};

type SignatureType = 'outlet' | 'subcontractor';

const dialogTitles: Record<SignatureType, string> = {
  outlet: 'Signature point de vente',
  subcontractor: 'Signature prestataire',
};

const checkStatus = (interventionStatus: string) => {
  // Status: >= scheduled, non-final
  const status = INTERVENTION_STATUSES.getById(interventionStatus);
  return status && status.id >= '_020_SCHEDULED' && !status.final;
};

const checkHasCompletedOperations = (addOperations = [], removeOperations = [], moveOperations = []) =>
  hasCompletedOperations(addOperations) ||
  hasCompletedOperations(removeOperations) ||
  hasCompletedOperations(moveOperations);

const checkHasAllSerialsNumbers = (addOperations = [], removeOperations = []) =>
  !hasMissingSerialNumbers(addOperations) && !hasMissingSerialNumbers(removeOperations);

const InterventionSignature = ({
  interventionId,
  interventionStatus,
  interventionRating,
  addOperations,
  removeOperations,
  moveOperations,
}: InterventionSignatureProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();
  const { outletSignature, subcontractorSignature } = useAppSelector(
    ({ interventionSignatures }) => interventionSignatures,
  );
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const canAddSignature = hasRole(currentUser, roles.intervention.process.code) && checkStatus(interventionStatus);
  const hasCompletedOps =
    canAddSignature && checkHasCompletedOperations(addOperations, removeOperations, moveOperations);
  const hasAllSerialNumbers = hasCompletedOps && checkHasAllSerialsNumbers(addOperations, removeOperations);

  const [signatureType, setSignatureType] = useState<SignatureType>();
  const [signature, setSignature] = useState<Attachment>();
  const [rating, setRating] = useState();
  const canvas = useRef<typeof DrawingCanvas>();

  const editable = canAddSignature && hasCompletedOps && hasAllSerialNumbers;
  const valid = Boolean(signature && (signatureType !== 'outlet' || rating));

  const onOpen = (type: SignatureType) => {
    setSignatureType(type);
  };

  const onClose = () => {
    setSignatureType(undefined);
  };

  const onChangeSignature = (signature: Attachment) => {
    setSignature(signature);
  };

  const onResetSignature = () => {
    setSignature(undefined);
    // @ts-ignore
    canvas.current?.resetDrawing();
  };

  const onChangeRating = (e, rating) => {
    setRating(rating);
  };

  const onSaveSignature = () => {
    if (signatureType === 'outlet') {
      dispatch(saveOutletSignature(interventionId, signature, rating));
    } else if (signatureType === 'subcontractor') {
      dispatch(saveSubcontractorSignature(interventionId, signature));
    }

    onClose();
    setSignature(undefined);
  };

  return (
    <CardContent>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className={classes.colBorder}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">Signature point de vente</Typography>
            </Grid>
            <Grid item xs={12} className={classes.signature}>
              {outletSignature ? (
                <img className={classes.signatureImage} alt="" src={attachmentUrl(outletSignature)} />
              ) : (
                editable && (
                  <Button variant="contained" onClick={() => onOpen('outlet')} startIcon={<Pen />}>
                    Ajouter la signature PDV
                  </Button>
                )
              )}
            </Grid>
            {Boolean(interventionRating) && (
              <Grid item xs={12} className={classes.ratingDisplay}>
                <Rating readOnly={true} max={4} size="large" value={interventionRating} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">Signature prestataire</Typography>
            </Grid>
            <Grid item xs={12} className={classes.signature}>
              {subcontractorSignature ? (
                <img className={classes.signatureImage} alt="" src={attachmentUrl(subcontractorSignature)} />
              ) : (
                editable && (
                  <Button variant="contained" onClick={() => onOpen('subcontractor')} startIcon={<Pen />}>
                    Ajouter la signature Prestataire
                  </Button>
                )
              )}
            </Grid>
          </Grid>
        </Grid>

        {canAddSignature && !hasCompletedOps && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="error">
              Veuillez déclarer une prestation comme faite avant de signer l'intervention
            </Typography>
          </Grid>
        )}
        {canAddSignature && hasCompletedOps && !hasAllSerialNumbers && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="error">
              Veuillez saisir tous les numéros de série avant de signer l'intervention
            </Typography>
          </Grid>
        )}
      </Grid>

      <Dialog open={Boolean(signatureType)} onClose={onClose} fullScreen={fullScreen}>
        <DialogTitle>{dialogTitles[signatureType]}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box component="fieldset" border="transparent">
            <Typography component="legend">Signer ici:</Typography>
            <DrawingCanvas onChange={onChangeSignature} ref={canvas} />
          </Box>
          {signatureType === 'outlet' && (
            <Box component="fieldset" border="transparent">
              <Typography component="legend">Satisfaction</Typography>
              <Rating name="rating" max={4} size="large" value={rating} onChange={onChangeRating} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button style={{ marginRight: 'auto' }} onClick={onResetSignature}>
            Réinitialiser
          </Button>
          <Button onClick={onClose}>Annuler</Button>
          <Button color="primary" onClick={onSaveSignature} disabled={!valid}>
            Signer
          </Button>
        </DialogActions>
      </Dialog>
    </CardContent>
  );
};

export default InterventionSignature;

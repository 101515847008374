import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { COMPANIES, urls } from '../../constants/AppConstants';
import { useNavigate } from 'react-router-dom';
import { MouseEvent, useEffect, useState } from 'react';
import { Stock } from '../../model/model';
import { api, convert404ToNull, ignore401 } from '../../services/RestService';
import { formatDateTime } from '../../utils/date-utils';
import { CardHeader } from '../utils';
import StockEquipmentExport from './StockEquipmentExport';
import { PauseCircle } from 'mdi-material-ui';

const loadStocks = async (): Promise<Stock[]> => {
  const endpoint = api.custom(urls.stockEquipments);

  try {
    const response = await endpoint.get();
    return response.body(false);
  } catch (error) {
    return convert404ToNull(error).catch((error) => ignore401(error));
  }
};

const StockEquipmentOutletsTable = () => {
  const navigate = useNavigate();
  const [stocks, setStocks] = useState<Stock[]>([]);
  const [selectedStocks, setSelectedStocks] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const apiStocks = await loadStocks();

      if (apiStocks?.length === 1) {
        navigate(`/stock-equipments/${apiStocks[0].code}`);
      }

      setStocks(apiStocks);
      setSelectedStocks([]);
    })();
  }, [navigate]);

  const onRowClick = (outletCode: string) => {
    navigate(`/stock-equipments/${outletCode}`);
  };

  const onToggleLine = (code: string) => (event: MouseEvent) => {
    event.stopPropagation();
    if (selectedStocks.includes(code)) {
      setSelectedStocks(selectedStocks.filter((selectedStockCode) => selectedStockCode !== code));
    } else {
      setSelectedStocks([...selectedStocks, code]);
    }
  };

  const onToggleAll = () => {
    if (selectedStocks.length === stocks.length) {
      setSelectedStocks([]);
    } else {
      setSelectedStocks(stocks.map((stock) => stock.code));
    }
  };

  return (
    <>
      <CardHeader
        actions={
          <StockEquipmentExport stockOutletCodes={selectedStocks} buttonLabel="Exporter les stocks sélectionnés" />
        }
      >
        Stocks de matériels
      </CardHeader>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 0, p: 0 }}>
              <Tooltip title={selectedStocks.length === stocks.length ? 'Désélectionner tout' : 'Sélectionner tout'}>
                <Checkbox
                  checked={selectedStocks.length === stocks.length}
                  indeterminate={selectedStocks.length > 0 && selectedStocks.length < stocks.length}
                  onClick={onToggleAll}
                />
              </Tooltip>
            </TableCell>
            <TableCell>Stock</TableCell>
            <TableCell></TableCell>
            <TableCell>Dernier inventaire</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stocks.map((stock) => (
            <TableRow key={stock.code} onClick={() => onRowClick(stock.code)} hover>
              <TableCell sx={{ p: 0 }}>
                <Checkbox checked={selectedStocks.includes(stock.code)} onClick={onToggleLine(stock.code)} />
              </TableCell>
              <TableCell>
                {COMPANIES.getById(stock.company).icon} {stock.name}
              </TableCell>
              <TableCell>
                {stock.pausedInventory && (
                  <Tooltip title={`Le ${formatDateTime(stock.pausedInventory)}`}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <PauseCircle color="warning" />
                      Inventaire en pause
                    </Box>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>{formatDateTime(stock.lastInventory) || '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default StockEquipmentOutletsTable;

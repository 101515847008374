import { STOCK_REMOVAL_TARGETS_LOADED, urls } from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { AppDispatch } from '../store';

const stockRemovalTargetsCollection = api.all(urls.stockRemovalTargets);
stockRemovalTargetsCollection.isFetchNeeded = true;

export function loadStockRemovalTargets() {
  return (dispatch: AppDispatch): void => {
    stockRemovalTargetsCollection
      .getAll()
      .then((response): void => {
        const list = response.body(false);
        if (list && list.length) {
          stockRemovalTargetsCollection.isFetchNeeded = false;
        }
        dispatch({
          type: STOCK_REMOVAL_TARGETS_LOADED,
          stockRemovalTargets: list,
        });
      })
      .catch(ignore401);
  };
}

export function loadStockRemovalTargetsIfNeeded() {
  return (dispatch: AppDispatch): void => {
    if (stockRemovalTargetsCollection.isFetchNeeded) {
      loadStockRemovalTargets()(dispatch);
    }
  };
}

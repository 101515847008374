import { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AutocompleteField, FormActions } from '../utils';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Formsy from 'formsy-react';
import { EquipmentRemovalBatch } from '../../model/model';
import { api, convert404ToNull, ignore401 } from '../../services/RestService';
import { urls } from '../../constants/AppConstants';
import { AutocompleteOption } from '../utils/AutocompleteField';
import { sendRemovalBatch } from '../../actions/StockEquipmentActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import theme from '../theme';
import { loadStockRemovalTargetsIfNeeded } from '../../actions/StockRemovalTargetsActions';
import { Pencil, Plus, Send } from 'mdi-material-ui';

type StockEquipmentRemovalProps = {
  stockOutletCode: string;
};

const errorMessages = {
  preparation: {
    isDefaultRequiredValue: 'Veuillez sélectionner une préparation',
    isExisty: 'Veuillez sélectionner une préparation',
  },
};

export const loadBatches = async (stockOutletCode: string): Promise<EquipmentRemovalBatch[]> => {
  const endpoint = api.one(urls.stockEquipments, stockOutletCode).custom('removal').custom('prepared');

  try {
    const response = await endpoint.get();
    return response.body(false);
  } catch (error) {
    return convert404ToNull(error).catch((error) => ignore401(error));
  }
};

const StockEquipmentRemoval: FC<StockEquipmentRemovalProps> = ({ stockOutletCode }) => {
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [batches, setBatches] = useState<EquipmentRemovalBatch[]>([]);
  const [selectedBatch, setSelectedBatch] = useState<EquipmentRemovalBatch>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stockRemovalTargets = useAppSelector(({ stockRemovalTargets }) => stockRemovalTargets);

  const batchOptions: AutocompleteOption[] = useMemo(
    () =>
      batches.map(({ id, targetStockCode, stockEquipments }) => ({
        id,
        name: `Préparation vers ${stockRemovalTargets.getNameById(targetStockCode, (code) => code)} - ${
          stockEquipments.length
        } matériel${stockEquipments.length >= 2 ? 's' : ''}`,
      })),
    [batches, stockRemovalTargets],
  );

  useEffect(() => {
    (async () => {
      dispatch(loadStockRemovalTargetsIfNeeded());
      const newBatches = await loadBatches(stockOutletCode);
      if (newBatches?.length) {
        setBatches(newBatches);
        setSelectedBatch(newBatches.length === 1 ? newBatches[0] : undefined);
      } else {
        // If there are no existing batches, go to the creation page
        navigate(`/stock-equipments/${stockOutletCode}/removal/new`, { replace: true });
      }
    })();
  }, [dispatch, navigate, stockOutletCode]);

  const onSelectBatch = (batchId?: string) => {
    setSelectedBatch(batchId ? batches.find((batch) => batch.id === batchId) : undefined);
  };

  const handleSend = () => {
    dispatch(sendRemovalBatch(stockOutletCode, selectedBatch.id));
  };

  return (
    <Formsy onValidSubmit={handleSend} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Sortie de matériel</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item flex={1}>
                  {batches.length > 0 && (
                    <AutocompleteField
                      options={batchOptions}
                      label="Préparation"
                      fullWidth
                      name="preparation"
                      onChange={onSelectBatch}
                      value={selectedBatch?.id}
                      validations="isExisty"
                      validationErrors={errorMessages.preparation}
                      clearIcon={false}
                    />
                  )}
                </Grid>
                <Grid item xs={smallScreen && 12}>
                  <Button
                    fullWidth
                    key="cancel"
                    variant="contained"
                    component={RouterLink}
                    to={`/stock-equipments/${stockOutletCode}/removal/new`}
                    startIcon={<Plus />}
                  >
                    Nouvelle préparation
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {selectedBatch && (
          <Grid item xs={12}>
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produit</TableCell>
                    <TableCell>Référence</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedBatch.stockEquipments.map((stockEquipment) => (
                    <TableRow key={stockEquipment.serialNumber}>
                      <TableCell>{stockEquipment.equipmentName}</TableCell>
                      <TableCell>{stockEquipment.serialNumber}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </Grid>
        )}

        <Grid item xs={12}>
          <Card>
            <FormActions
              rightActions={
                <>
                  <Button variant="contained" onClick={() => navigate(-1)}>
                    Annuler
                  </Button>
                  {selectedBatch && (
                    <Button
                      variant="contained"
                      component={RouterLink}
                      to={`/stock-equipments/${stockOutletCode}/removal/${selectedBatch.id}`}
                      startIcon={<Pencil />}
                    >
                      Modifier la préparation
                    </Button>
                  )}
                  <Button variant="contained" color="primary" type="submit" startIcon={<Send />}>
                    Envoyer
                    {selectedBatch &&
                      stockRemovalTargets &&
                      ` à ${stockRemovalTargets.getNameById(selectedBatch.targetStockCode)}`}
                  </Button>
                </>
              }
            />
          </Card>
        </Grid>
      </Grid>
    </Formsy>
  );
};

export default StockEquipmentRemoval;

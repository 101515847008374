import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import { Download } from 'mdi-material-ui';

import { downloadUrl } from '../../actions/DownloadActions';
import { useAppDispatch } from '../../hooks';
import { makeStyles } from '@mui/styles';
import { stockEquipmentExportUrl } from '../../actions/StockEquipmentActions';

const useStyles = makeStyles((theme) => ({
  buttonLabel: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

type StockEquipmentExportProps = {
  stockOutletCodes: string[];
  buttonLabel?: ReactNode;
};

const StockEquipmentExport = ({ stockOutletCodes, buttonLabel = 'Exporter le stock' }: StockEquipmentExportProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  return (
    <Button
      disabled={!stockOutletCodes.length}
      onClick={() => dispatch(downloadUrl(stockEquipmentExportUrl(stockOutletCodes), 'Stocks.csv'))}
    >
      <Download />
      <span className={classes.buttonLabel}>{buttonLabel}</span>
    </Button>
  );
};

export default StockEquipmentExport;

import { AutocompleteFieldProps, AutocompleteOption } from '../utils/AutocompleteField';
import { ElementType, useEffect } from 'react';
import { ChipTypeMap } from '@mui/material/Chip';
import { AutocompleteField } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { loadDemandTypesIfNeeded } from '../../actions/DemandTypeActions';
import { loadRegions } from '../../actions/settings/RegionActions';
import { loadAgencies } from '../../actions/settings/AgencyActions';
import { loadAreas } from '../../actions/settings/AreaActions';
import { compareByName } from '../../utils/sorting';
import { loadAgreementTypesIfNeeded } from '../../actions/fdd/FDDAgreementTypeActions';
import { loadGoalsIfNeeded } from '../../actions/fdd/FDDGoalActions';
import { loadSubGoalsIfNeeded } from '../../actions/fdd/FDDSubGoalActions';
import { loadStockRemovalTargetsIfNeeded } from '../../actions/StockRemovalTargetsActions';

const buildAutocompleteField =
  <
    T extends AutocompleteOption,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
    F = {},
  >(
    selectResources: (state: any) => T[],
    loadOptions: () => any,
    filter: (filterProps: F) => (item: T) => boolean = () => (item) => true,
  ) =>
  (props: Omit<AutocompleteFieldProps<T, Multiple, DisableClearable, ChipComponent>, 'options'> & F) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(loadOptions());
    }, [dispatch]);
    const options = (useSelector(selectResources) ?? []).filter(filter(props));

    return <AutocompleteField options={options} {...props} />;
  };

export const DemandTypeAutocompleteField = buildAutocompleteField(
  ({ demandTypes }) => demandTypes,
  loadDemandTypesIfNeeded,
);

type RegionFilterProps = {
  regionIds?: string[];
};

type AgencyFilterProps = RegionFilterProps & {
  agencyIds?: string[];
};

type WhiteListFilterProps = {
  whiteList?: string[];
};

const filterByRegions =
  ({ regionIds }: RegionFilterProps) =>
  (agency) => {
    if (regionIds && regionIds.length) {
      return regionIds.some((regionId) => regionId === agency.region.id);
    }
    return true;
  };

const filterByAgencies =
  ({ agencyIds, regionIds }: AgencyFilterProps) =>
  (area) => {
    if (agencyIds && agencyIds.length) {
      return agencyIds.some((agencyId) => agencyId === area.agency.id);
    }
    if (regionIds && regionIds.length) {
      return regionIds.some((regionId) => regionId === area.region.id);
    }
    return true;
  };

const filterByWhiteList =
  ({ whiteList }: WhiteListFilterProps) =>
  (item) =>
    !whiteList || whiteList.includes(item.id);

export const RegionAutocompleteField = buildAutocompleteField(({ regions }) => regions, loadRegions);
export const AgencyAutocompleteField = buildAutocompleteField(
  ({ agencies: { list } }) => list,
  loadAgencies,
  filterByRegions,
);
export const AreaAutocompleteField = buildAutocompleteField(({ areas: { list } }) => list, loadAreas, filterByAgencies);

export const FDDAgreementTypeAutocompleteField = buildAutocompleteField(
  ({ fddAgreementTypes, fdd: { fdd } }) => {
    if (fddAgreementTypes && fdd && fdd.agreementTypes && fdd.agreementTypes.length) {
      // Add the current FDD agreement types in case they have been deleted
      const agreementTypes = [...fddAgreementTypes];
      fdd.agreementTypes.forEach((fddAgreementType) => {
        if (agreementTypes.every((agreementType) => agreementType.id !== fddAgreementType.id)) {
          agreementTypes.push(fddAgreementType);
        }
      });
      return agreementTypes.sort(compareByName);
    }
    return fddAgreementTypes;
  },
  loadAgreementTypesIfNeeded,
  filterByWhiteList,
);
export const FDDGoalAutocompleteField = buildAutocompleteField(({ fddGoals, fdd: { fdd } }) => {
  if (fdd && fdd.goal && fddGoals.every((goal) => goal.id !== fdd.goal.id)) {
    // Add the current FDD goal in case it has been deleted
    return [...fddGoals, fdd.goal].sort(compareByName);
  }
  return fddGoals;
}, loadGoalsIfNeeded);

export const FDDSubGoalAutocompleteField = buildAutocompleteField(({ fddSubGoals, fdd: { fdd } }) => {
  if (fdd?.subGoal && fddSubGoals.every((subGoal) => subGoal.id !== fdd.subGoal?.id)) {
    // Add the current FDD sub goal in case it has been deleted
    return [...fddSubGoals, fdd.subGoal].sort(compareByName);
  }
  return fddSubGoals;
}, loadSubGoalsIfNeeded);

export { default as OutletAutocompleteField } from './OutletAutocompleteField';

export const StockRemovalTargetAutocompleteField = buildAutocompleteField(
  ({ stockRemovalTargets }) => stockRemovalTargets,
  loadStockRemovalTargetsIfNeeded,
);

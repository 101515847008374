/**
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Drawer, Hidden, SwipeableDrawer } from '@mui/material';

import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';
import { checkCurrentUser } from '../../actions/CurrentUserActions';
import OutletListPage from '../pages/outlets/OutletListPage';
import OutletPage from '../pages/outlets/OutletPage';
import DemandListPage from '../pages/demands/DemandListPage';
import DemandPage from '../pages/demands/DemandPage';
import DemandInvoicesImportPage from '../pages/demands/DemandInvoicesImportPage';
import InterventionListPage from '../pages/interventions/InterventionListPage';
import InterventionCalendarPage from '../pages/interventions/InterventionCalendarPage';
import InterventionMapPage from '../pages/interventions/InterventionMapPage';
import InterventionPage from '../pages/interventions/InterventionPage';
import EquipmentMoveListPage from '../pages/equipment-moves/EquipmentMoveListPage';
import NewEquipmentMovePage from '../pages/equipment-moves/NewEquipmentMovePage';
import EquipmentExportPage from '../pages/dashboards/EquipmentExportPage';
import DayActivityPage from '../pages/dashboards/DayActivityPage';
import FDDListPage from '../pages/fdd/FDDListPage';
import FDDPage from '../pages/fdd/FDDPage';
import EquipmentsPage from '../pages/settings/EquipmentsPage';
import EquipmentPage from '../pages/settings/EquipmentPage';
import RegionsPage from '../pages/settings/RegionsPage';
import AgenciesPage from '../pages/settings/AgenciesPage';
import AreasPage from '../pages/settings/AreasPage';
import OperationBundlesPage from '../pages/settings/OperationBundlesPage';
import OperationBundlePage from '../pages/settings/OperationBundlePage';
import OperationOptionsPage from '../pages/settings/OperationOptionsPage';
import OperationOptionPage from '../pages/settings/OperationOptionPage';
import AfterSalesOperationsPage from '../pages/settings/AfterSalesOperationsPage';
import AfterSalesOperationPage from '../pages/settings/AfterSalesOperationPage';
import DemandTypesPage from '../pages/settings/DemandTypesPage';
import DeliveryLocationsPage from '../pages/settings/DeliveryLocationsPage';
import ReloadDemandsPage from '../pages/settings/ReloadDemandsPage';
import FDDSettingsPage from '../pages/settings/FDDSettingsPage';
import UsersPage from '../pages/security/UsersPage';
import UserPage from '../pages/security/UserPage';
import UserProfilesPage from '../pages/security/UserProfilesPage';
import AccountPage from '../pages/AccountPage';
import NotFoundPage from '../pages/NotFoundPage';
import IndexPage from '../pages/IndexPage';
import { closeMainMenu, toggleMainMenu } from '../../actions/AppActions';
import DialogBox from '../utils/DialogBox';
import DemandImportPage from '../pages/demands/DemandImportPage';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../../hooks';
import StocksPage from '../pages/stock-equipment/StocksPage';
import StockEquipmentPage from '../pages/stock-equipment/StockEquipmentPage';
import StockEquipmentReceptionPage from '../pages/stock-equipment/StockEquipmentReceptionPage';
import StockEquipmentRemovalPreparationPage from '../pages/stock-equipment/StockEquipmentRemovalPreparationPage';
import StockEquipmentRemovalPage from '../pages/stock-equipment/StockEquipmentRemovalPage';
import StockEquipmentInventoryPage from '../pages/stock-equipment/StockEquipmentInventoryPage';

const drawerWidth = 268;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '&.MuiDrawer-modal': {
      zIndex: 1300,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbarPlaceholder: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    padding: theme.spacing(3),
    paddingBottom: 80,
    minHeight: '100vh',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      maxWidth: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
  },
}));

const Main = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const menuOpened = useAppSelector(({ mainMenu: { opened } }) => opened);

  useEffect(() => {
    dispatch(checkCurrentUser());
  }, [dispatch]);

  const onOpenMenu = () => dispatch(toggleMainMenu());
  const onCloseMenu = () => dispatch(closeMainMenu());

  return (
    <div className={classes.root}>
      <Header />
      <Hidden lgUp>
        <SwipeableDrawer
          open={menuOpened}
          onOpen={onOpenMenu}
          onClose={onCloseMenu}
          classes={{
            root: classes.drawer,
            paper: classes.drawerPaper,
          }}
        >
          <Menu />
        </SwipeableDrawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          variant="permanent"
          classes={{
            root: classes.drawer,
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.toolbarPlaceholder} />
          <Menu />
        </Drawer>
      </Hidden>
      <div className={classes.content}>
        <div className={classes.toolbarPlaceholder} />
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/outlets" element={<OutletListPage />} />
          <Route path="/outlets/:outletId" element={<OutletPage />} />
          <Route path="/demands" element={<DemandListPage />} />
          <Route path="/demands/new" element={<DemandPage />} />
          <Route path="/demands/import" element={<DemandImportPage />} />
          <Route path="/demands/import-invoices" element={<DemandInvoicesImportPage />} />
          <Route path="/demands/:demandId" element={<DemandPage />} />
          <Route path="/interventions/list" element={<InterventionListPage />} />
          <Route path="/interventions/planning" element={<InterventionCalendarPage />} />
          <Route path="/interventions/map" element={<InterventionMapPage />} />
          <Route path="/interventions/:interventionId" element={<InterventionPage />} />
          <Route path="/equipment-moves" element={<EquipmentMoveListPage />} />
          <Route path="/equipment-moves/new" element={<NewEquipmentMovePage />} />
          <Route path="/dashboards/equipments" element={<EquipmentExportPage />} />
          <Route path="/dashboards/day" element={<DayActivityPage />} />
          <Route path="/fdd" element={<FDDListPage />} />
          <Route path="/fdd/new" element={<FDDPage />} />
          <Route path="/fdd/:fddId" element={<FDDPage />} />
          <Route path="/stock-equipments" element={<StocksPage />} />
          <Route path="/stock-equipments/:stockOutletCode" element={<StockEquipmentPage />} />
          <Route path="/stock-equipments/:stockOutletCode/inventory" element={<StockEquipmentInventoryPage />} />
          <Route path="/stock-equipments/:stockOutletCode/reception" element={<StockEquipmentReceptionPage />} />
          <Route path="/stock-equipments/:stockOutletCode/removal" element={<StockEquipmentRemovalPage />} />
          <Route
            path="/stock-equipments/:stockOutletCode/removal/new"
            element={<StockEquipmentRemovalPreparationPage />}
          />
          <Route
            path="/stock-equipments/:stockOutletCode/removal/:batchId"
            element={<StockEquipmentRemovalPreparationPage />}
          />
          <Route path="/settings/equipments" element={<EquipmentsPage />} />
          <Route path="/settings/equipments/new" element={<EquipmentPage />} />
          <Route path="/settings/equipments/:equipmentId" element={<EquipmentPage />} />
          <Route path="/settings/regions" element={<RegionsPage />} />
          <Route path="/settings/agencies" element={<AgenciesPage />} />
          <Route path="/settings/areas" element={<AreasPage />} />
          <Route path="/settings/operation-bundles" element={<OperationBundlesPage />} />
          <Route path="/settings/operation-bundles/new" element={<OperationBundlePage />} />
          <Route path="/settings/operation-bundles/:bundleId" element={<OperationBundlePage />} />
          <Route path="/settings/operation-options" element={<OperationOptionsPage />} />
          <Route path="/settings/operation-options/new" element={<OperationOptionPage />} />
          <Route path="/settings/operation-options/:optionId" element={<OperationOptionPage />} />
          <Route path="/settings/after-sales-operations" element={<AfterSalesOperationsPage />} />
          <Route path="/settings/after-sales-operations/new" element={<AfterSalesOperationPage />} />
          <Route path="/settings/after-sales-operations/:afterSalesOperationId" element={<AfterSalesOperationPage />} />
          <Route path="/settings/demand-types" element={<DemandTypesPage />} />
          <Route path="/settings/delivery-locations" element={<DeliveryLocationsPage />} />
          <Route path="/settings/reload-demands" element={<ReloadDemandsPage />} />
          <Route path="/settings/fdd" element={<FDDSettingsPage />} />
          <Route path="/users/users" element={<UsersPage />} />
          <Route path="/users/users/new" element={<UserPage />} />
          <Route path="/users/users/:userId" element={<UserPage />} />
          <Route path="/users/profiles" element={<UserProfilesPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
        <DialogBox />
      </div>
    </div>
  );
};

export default Main;

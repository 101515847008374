import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { LoadingMessage } from '../utils';
import { EQUIPMENT_STATES } from '../../constants/AppConstants';
import { formatDateTime } from '../../utils/date-utils';
import { SyncAlert } from 'mdi-material-ui';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '../../hooks';
import theme from '../theme';
import dayjs from 'dayjs';
import { StockEquipment } from '../../model/model';

const useStyles = makeStyles((theme) => ({
  serialNumberCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:first-child': {
      paddingLeft: theme.spacing(6),
    },
  },
  syncIcon: {
    position: 'absolute',
  },
  refresh: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  listRow: {
    '&:nth-of-type(2n)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

const StockEquipmentState = ({ equipment }: { equipment: StockEquipment }) => {
  const content = (
    <span>
      {EQUIPMENT_STATES.getNameById(equipment.state)}
      {equipment.state === 'INVENTORY_ERROR' &&
        equipment.stateUpdateDate &&
        dayjs(equipment.stateUpdateDate).diff(dayjs(), 'day') >= 1 &&
        ` (depuis ${dayjs(equipment.stateUpdateDate).fromNow(true)})`}
    </span>
  );
  return equipment.lastState ? (
    <Tooltip
      title={
        <>
          Statut précédent : {EQUIPMENT_STATES.getNameById(equipment.lastState)}
          <br />
          Modifié {dayjs(equipment.stateUpdateDate).fromNow()}
        </>
      }
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

const StockEquipmentTable = () => {
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const loading = useAppSelector(({ stockEquipments: { loading } }) => loading);
  const stockEquipments = useAppSelector(({ stockEquipments: { stockEquipments } }) => stockEquipments || []);
  const serverError = useAppSelector(({ stockEquipments: { error } }) => error);

  const classes = useStyles();
  if (loading || serverError) {
    return <LoadingMessage loading={loading} serverError={serverError} />;
  }

  const stockEquipmentsByType = stockEquipments.reduce<Record<string, StockEquipment[]>>((equipmentType, equipment) => {
    const { equipmentCode, equipmentName } = equipment;
    const equipmentLabel = `${equipmentName} - ${equipmentCode}`;
    equipmentType[equipmentLabel] = equipmentType[equipmentLabel] ?? [];
    equipmentType[equipmentLabel].push(equipment);
    return equipmentType;
  }, {});

  return !stockEquipments.length ? (
    <Card>
      <CardContent>
        <Typography>Aucun stock pour cette société</Typography>
      </CardContent>
    </Card>
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Matériel</TableCell>
                <TableCell>Statut</TableCell>
                {mediumScreen && (
                  <React.Fragment>
                    <TableCell>Dernière mise à jour</TableCell>
                    <TableCell>Quantité</TableCell>
                  </React.Fragment>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(stockEquipmentsByType).map((type) => {
                const equipments = stockEquipmentsByType[type];
                return (
                  <React.Fragment key={type}>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <strong>
                          {type} {!mediumScreen ? `(${equipments.length})` : ''}
                        </strong>
                      </TableCell>
                      {mediumScreen && <TableCell>{equipments.length}</TableCell>}
                    </TableRow>
                    {equipments.map((equipment, index) => (
                      <TableRow key={`${equipments}-${index}`}>
                        <TableCell className={classes.serialNumberCell}>{equipment.serialNumber}</TableCell>
                        <TableCell>
                          <StockEquipmentState equipment={equipment} />
                        </TableCell>
                        {mediumScreen && (
                          <React.Fragment>
                            <TableCell>
                              {formatDateTime(equipment.updateDate)}
                              {equipment.syncError ? (
                                <Tooltip title="Erreur avec le stock PMU">
                                  <SyncAlert className={classes.syncIcon} />
                                </Tooltip>
                              ) : (
                                <div />
                              )}
                            </TableCell>
                            <TableCell />
                          </React.Fragment>
                        )}
                      </TableRow>
                    ))}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StockEquipmentTable;

import { FC, useEffect, useState } from 'react';
import { ApiDate, StockBatchElement } from '../../model/model';
import { Button, Card, Grid, Table, TableBody, Tooltip, Typography } from '@mui/material';
import Formsy from 'formsy-react';
import { FormActions } from '../utils';
import { v4 as uuid } from 'uuid';
import { useAppDispatch } from '../../hooks';
import StockEquipmentRow from './StockEquipmentRow';
import { useNavigate } from 'react-router-dom';
import { loadActiveEquipmentsWithSerialNumber } from '../../actions/settings/EquipmentActions';
import { addGlobalError, addGlobalMessage } from '../../actions/SnackbarActions';
import { api, errorIs401, errorIs404 } from '../../services/RestService';
import { urls } from '../../constants/AppConstants';
import { formatDateTime } from '../../utils/date-utils';
import { Check, Close, PauseCircle } from 'mdi-material-ui';

type StockEquipmentInventoryFieldProps = {
  stockOutletCode: string;
};

const StockEquipmentInventory: FC<StockEquipmentInventoryFieldProps> = ({ stockOutletCode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadActiveEquipmentsWithSerialNumber());
  }, [dispatch]);

  const getEmptyItem = (): StockBatchElement => {
    return {
      id: uuid(),
      serialNumber: null,
      equipmentName: null,
      equipmentCode: null,
    };
  };

  const [items, setItems] = useState([getEmptyItem()]);
  const [pausedDate, setPausedDate] = useState<ApiDate>();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.one(urls.stockEquipments, stockOutletCode).custom('inventory').get();
        const { inventoryElements, pausedDate } = response.body(false);
        setItems([...inventoryElements, getEmptyItem()]);
        setPausedDate(pausedDate);
      } catch (error) {
        if (!errorIs401(error) && !errorIs404(error)) {
          console.error('Error while fetching paused inventory:', error.response);
        }
      }
    })();
  }, [stockOutletCode]);

  const onSubmit = async () => {
    const itemsToSave = items.filter((item) => item.serialNumber);
    if (itemsToSave?.length) {
      try {
        await api.one(urls.stockEquipments, stockOutletCode).custom('inventory').post(itemsToSave);
        navigate(`/stock-equipments/${stockOutletCode}`);
        dispatch(addGlobalMessage("L'inventaire a été enregistré"));
      } catch (error) {
        if (!errorIs401(error)) {
          console.error('Error while saving inventory:', error.response);
          dispatch(addGlobalError("Erreur pendant l'enregistrement"));
        }
      }
    } else {
      dispatch(addGlobalError('Veuillez renseigner au moins un matériel.'));
    }
  };

  const onPause = async () => {
    const itemsToSave = items.filter((item) => item.serialNumber);
    try {
      await api.one(urls.stockEquipments, stockOutletCode).custom('inventory').put(itemsToSave);
      navigate(`/stock-equipments/${stockOutletCode}`);
      dispatch(addGlobalMessage("L'inventaire a été mis en pause"));
    } catch (error) {
      if (!errorIs401(error)) {
        console.error('Error while saving inventory:', error.response);
        dispatch(addGlobalError("Erreur pendant l'enregistrement"));
      }
    }
  };

  const onCancel = async () => {
    if (pausedDate) {
      try {
        await api.one(urls.stockEquipments, stockOutletCode).custom('inventory').delete();
      } catch (error) {
        if (!errorIs401(error)) {
          console.error('Error while deleting inventory:', error.response);
        }
      }
    }
    navigate(-1);
  };

  const onRemoveItem = (id: string) => {
    let newItems = items.filter((item) => item.id !== id);
    if (!newItems.length) {
      newItems = [getEmptyItem()];
    }
    setItems(newItems);
  };

  const onEquipmentChange = (stockEquipment: StockBatchElement) => {
    let newItems: StockBatchElement[] = items.map((item) => {
      return item.id === stockEquipment.id
        ? {
            ...item,
            serialNumber: stockEquipment?.serialNumber ?? null,
            equipmentCode: stockEquipment?.equipmentCode ?? null,
            equipmentName: stockEquipment?.equipmentName ?? null,
          }
        : item;
    });

    if (newItems.every((item) => item.serialNumber)) {
      setItems([...newItems, getEmptyItem()]);
    } else {
      setItems(newItems);
    }
  };

  return (
    <Formsy onValidSubmit={onSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">
            Saisie d'inventaire
            {pausedDate && ` (mis en pause le ${formatDateTime(pausedDate)})`}
          </Typography>
        </Grid>

        <Grid item container xs={12} marginTop={2}>
          <Card>
            <Table>
              <TableBody>
                {items.map((item: StockBatchElement) => (
                  <StockEquipmentRow
                    key={item.id}
                    stockBatchElement={item}
                    onRemoveItem={onRemoveItem}
                    onEquipmentChange={onEquipmentChange}
                    handleState={false}
                  />
                ))}
              </TableBody>
            </Table>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <FormActions
              rightActions={
                <>
                  <Tooltip title="Annuler l'inventaire, et perdre la saisie">
                    <Button variant="contained" onClick={onCancel} startIcon={<Close />}>
                      Annuler l'inventaire
                    </Button>
                  </Tooltip>
                  <Tooltip title="Enregistrer la saisie, et continuer l'inventaire plus tard">
                    <Button variant="contained" color="info" onClick={onPause} startIcon={<PauseCircle />}>
                      Mettre en pause
                    </Button>
                  </Tooltip>
                  <Button variant="contained" color="primary" type="submit" startIcon={<Check />}>
                    Valider l'inventaire
                  </Button>
                </>
              }
            />
          </Card>
        </Grid>
      </Grid>
    </Formsy>
  );
};

export default StockEquipmentInventory;

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debugFactory from 'debug';
import Formsy from 'formsy-react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, Grid, Link, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SwipeableViews from 'react-swipeable-views';
import { Camera, ClockIn, ClockOut, ContentSave, FormatListChecks, History, Pen, Truck } from 'mdi-material-ui';

import InterventionOperations from './InterventionOperations';
import InterventionAttachments from './InterventionAttachments';
import InterventionSignature from './InterventionSignature';
import InterventionHistory from './InterventionHistory';
import InterventionsButtons from './InterventionsButtons';
import InterventionGlobalInformation from './InterventionGlobalInformation';
import InterventionDates from './InterventionDates';
import InterventionCommands from './InterventionCommands';
import { loadIntervention, updateIntervention } from '../../actions/InterventionActions';
import { loadDeliveryLocationsIfNeeded } from '../../actions/DeliveryLocationsActions';
import { loadUsersIfNeeded } from '../../actions/UserActions';
import { loadDemandTypesIfNeeded } from '../../actions/DemandTypeActions';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';
import { CONCEPTS, DEMAND_STATUSES, INTERVENTION_STATUSES } from '../../constants/AppConstants';
import { AutocompleteField, LabelValue, LoadingMessage, ResponsiveTab, ResponsiveTabs, TextField } from '../utils';
import DateViewer from '../commons/DateViewer';
import { hasNonDummyEquipments } from '../../utils/operation-utils';
import dayjs from 'dayjs';
import { compose } from 'recompose';
import { withPageTitle } from '../../utils/page-title';
import { addMaxValidationRule, addMinValidationRule } from '../../utils/validation-rules';
import { roundDate } from '../../utils/date-utils';
import { stickyActionBar } from '../utils/commonStyles';
import classNames from 'classnames';

const debug = debugFactory('prestago:Demand');

const styles = (theme) => ({
  clockInOutButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  actions: {
    // Space on the bottom of the page to allow opening the Delivery Location Select fully
    marginBottom: 150,
  },
  stickyActionBar,
});

class Intervention extends Component {
  static propTypes = {
    interventionId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      /*
       * Intervention fields
       */
      subcontractorReference: '',
      teamCode: '',
      assignedUserId: null,
      assignedUser: {},
      comment: '',
      operationScheduleDate: null,
      operationScheduleDayTime: null,
      operationStartedDate: null,
      operationFinishedDate: null,

      /*
       * Other fields
       */
      activeTabIndex: 0,
      readOnly: true,
      userReadOnly: true,
    };
    addMinValidationRule();
    addMaxValidationRule();
  }

  UNSAFE_componentWillMount() {
    const { dispatch, interventionId, currentUser } = this.props;
    dispatch(loadIntervention(interventionId));
    const hasAssignRole = hasRole(currentUser, roles.subcontractor.interventionAssign.code);
    if (hasAssignRole) {
      debug('Loading users for assignment');
      dispatch(loadUsersIfNeeded());
    }
    dispatch(loadDeliveryLocationsIfNeeded());
    dispatch(loadDemandTypesIfNeeded());

    this.setState({
      readOnly: !hasRole(currentUser, roles.intervention.edit.code),
      userReadOnly: !hasAssignRole,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      interventionId: oldId,
      intervention: { intervention: oldIntervention, counter: oldCounter },
      dispatch,
    } = this.props;
    const {
      interventionId,
      intervention: { intervention, counter },
    } = nextProps;

    // Id parameter changed - Load the intervention
    if (interventionId !== oldId) {
      debug('Loading intervention with id %s', interventionId);
      dispatch(loadIntervention(interventionId));
    }

    // Demand in store changed - reload the demand
    if (intervention.id !== (oldIntervention && oldIntervention.id) || counter !== oldCounter) {
      this.reloadWithIntervention(intervention);
    }
  }

  componentDidUpdate() {
    this.updateTabsHeight();
  }

  onTabChange = (activeTabIndex) => {
    this.setState({ activeTabIndex });
  };

  onChange = (fieldName) => (value) => {
    const newState = {};
    newState[fieldName] = value;
    this.setState(newState);
  };

  onSave = () => {
    const {
      dispatch,
      intervention: {
        intervention: { id },
      },
    } = this.props;
    const {
      subcontractorReference,
      teamCode,
      assignedUserId,
      comment,
      operationScheduleDate,
      operationScheduleDayTime,
      operationStartedDate,
      operationFinishedDate,
    } = this.state;
    dispatch(
      updateIntervention({
        id,
        subcontractorReference,
        teamCode,
        assignedUserId,
        comment,
        operationScheduleDate,
        operationScheduleDayTime,
        operationStartedDate,
        operationFinishedDate,
      }),
    );
  };

  clockIn = () => {
    this.setState(
      {
        operationStartedDate: roundDate(dayjs(), 5, 'minutes').format(),
      },
      this.onSave,
    );
  };

  clockOut = () => {
    this.setState(
      {
        operationFinishedDate: roundDate(dayjs(), 5, 'minutes').format(),
      },
      this.onSave,
    );
  };

  reloadWithIntervention = (intervention) => {
    const { currentUser } = this.props;
    const status = INTERVENTION_STATUSES.getById(intervention.status);
    this.setState({
      subcontractorReference: intervention.subcontractorReference,
      teamCode: intervention.teamCode,
      assignedUserId: intervention.assignedUserId,
      assignedUser: intervention.assignedUser,
      comment: intervention.comment,
      operationScheduleDate: intervention.operationScheduleDate,
      operationScheduleDayTime: intervention.operationScheduleDayTime,
      operationStartedDate: intervention.operationStartedDate,
      operationFinishedDate: intervention.operationFinishedDate,
      readOnly: !hasRole(currentUser, roles.intervention.edit.code) || (status && status.final),
      userReadOnly: !hasRole(currentUser, roles.subcontractor.interventionAssign.code) || (status && status.final),
    });
  };

  updateTabsHeight = () => {
    if (this.swipeableActions) {
      this.swipeableActions.updateHeight();
    }
  };

  render() {
    const {
      intervention: {
        intervention: {
          id,
          number,
          status,
          demandId,
          demandNumber,
          concept,
          demandTypeId,
          demandTypeName,
          demandStatus,
          demandValidationDate,
          demandInterventions,
          outletId,
          outletCode,
          outletName,
          originOutlet,
          events,
          agency,
          area,
          oracleCommands,
          rating,
          operationScheduleDate: operationScheduleDateProp,
          operationStartedDate: operationStartedDateProp,
          operationFinishedDate: operationFinishedDateProp,
        },
        loading,
        error,
      },
      operations: { addOperations, moveOperations, removeOperations },
      signatures: { outletSignature, subcontractorSignature },
      users,
      hasRealEquipments,
      demandTypes,
      classes,
    } = this.props;
    const {
      subcontractorReference,
      teamCode,
      assignedUserId,
      assignedUser,
      comment,
      operationScheduleDate,
      operationScheduleDayTime,
      operationStartedDate,
      operationFinishedDate,
      readOnly,
      userReadOnly,
      activeTabIndex,
    } = this.state;

    const multiOutlet = originOutlet && originOutlet.code;
    const isAgency = !area || !area.id;

    const demandType = demandTypeId && demandTypes.getById(demandTypeId);

    const canHaveDelivery = Boolean(hasRealEquipments && demandType?.oracleCode);
    const showAddDelivery = Boolean(canHaveDelivery && status >= '_020_SCHEDULED');
    const showDeliveries = Boolean(showAddDelivery || (oracleCommands && oracleCommands.length));

    if (loading || error) {
      return (
        <LoadingMessage loading={loading} serverError={error}>
          Intervention
        </LoadingMessage>
      );
    }

    const tabs = [
      <InterventionOperations
        key="operations"
        interventionId={id}
        addOperations={addOperations}
        moveOperations={moveOperations}
        removeOperations={removeOperations}
        multiOutlet={multiOutlet}
        showDeliveryStatus={showDeliveries}
        showAddDelivery={showAddDelivery}
        updateTabsHeight={this.updateTabsHeight}
      />,
      <InterventionAttachments
        key="attachments"
        interventionId={id}
        onAttachmentsChange={() => this.onTabChange(activeTabIndex)}
      />,
      <InterventionSignature
        key="signatures"
        interventionId={id}
        interventionStatus={status}
        interventionRating={rating}
        addOperations={addOperations}
        moveOperations={moveOperations}
        removeOperations={removeOperations}
      />,
      <InterventionHistory key="history" events={events} />,
    ];
    if (showAddDelivery) {
      tabs.push(<InterventionCommands key="commands" readOnly={readOnly} />);
    }
    const scheduledToday = !readOnly && status === '_020_SCHEDULED' && dayjs().isSame(operationScheduleDateProp, 'day');

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">
            {outletCode} - {outletName} - Intervention {number}
          </Typography>
        </Grid>

        {scheduledToday && !operationStartedDateProp && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="button"
              color="primary"
              onClick={this.clockIn}
              className={classes.clockInOutButton}
            >
              <ClockIn />
              Début d'intervention
            </Button>
          </Grid>
        )}
        {scheduledToday && operationStartedDateProp && !operationFinishedDateProp && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="button"
              color="primary"
              onClick={this.clockOut}
              className={classes.clockInOutButton}
            >
              <ClockOut />
              Fin d'intervention
            </Button>
          </Grid>
        )}

        <Grid item xs={12} lg={6} container>
          <Card>
            <Formsy onValidSubmit={this.onSave} noValidate>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Détails de l'intervention</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InterventionGlobalInformation
                      status={status}
                      subcontractorReference={subcontractorReference}
                      teamCode={teamCode}
                      hideComment
                      readOnly={readOnly}
                      onChange={this.onChange}
                    >
                      {userReadOnly ? (
                        assignedUser && (
                          <Grid item xs={12}>
                            <LabelValue label="Affectation">
                              {assignedUser ? `${assignedUser.firstName} ${assignedUser.lastName}` : ''}
                            </LabelValue>
                          </Grid>
                        )
                      ) : (
                        <Grid item xs={12}>
                          <AutocompleteField
                            options={users}
                            getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
                            label="Affectation"
                            name="assignedUserId"
                            value={assignedUserId}
                            fullWidth
                            onChange={this.onChange('assignedUserId')}
                          />
                        </Grid>
                      )}
                    </InterventionGlobalInformation>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InterventionDates
                      status={status}
                      operationScheduleDate={operationScheduleDate}
                      operationScheduleDayTime={operationScheduleDayTime}
                      operationStartedDate={operationStartedDate}
                      operationFinishedDate={operationFinishedDate}
                      readOnly={readOnly}
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={readOnly}
                      label="Commentaires"
                      fullWidth
                      placeholder={readOnly ? '' : "Exemple : pose d'une borne"}
                      multiline
                      name="comment"
                      onChange={(event) => this.onChange('comment')(event.target.value)}
                      value={comment}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              {!readOnly && (
                <CardActions>
                  <Button variant="contained" type="submit" color="primary" startIcon={<ContentSave />}>
                    Enregistrer
                  </Button>
                </CardActions>
              )}
            </Formsy>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} lg={3} container>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h2">Demande</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LabelValue label="Numéro">
                    {demandNumber && (
                      <Link component={RouterLink} to={`/demands/${demandId}`}>
                        {demandNumber}
                      </Link>
                    )}
                  </LabelValue>
                </Grid>
                <Grid item xs={12}>
                  <LabelValue label="Type">{demandTypeName}</LabelValue>
                </Grid>
                <Grid item xs={12}>
                  <LabelValue label="Concept">{CONCEPTS.getNameById(concept)}</LabelValue>
                </Grid>
                <Grid item xs={12}>
                  <LabelValue label="Statut">{DEMAND_STATUSES.getNameById(demandStatus)}</LabelValue>
                </Grid>
                <Grid item xs={12}>
                  <LabelValue label="Date de validation">
                    <DateViewer date={demandValidationDate} />
                  </LabelValue>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} lg={3} container>
          <Grid container spacing={3}>
            <Grid item xs={12} container>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h2">Point de vente {multiOutlet ? 'Destination' : ''}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <LabelValue label="Code">
                        {outletCode && (
                          <Link component={RouterLink} to={`/outlets/${outletId}`}>
                            {outletCode}
                          </Link>
                        )}
                      </LabelValue>
                    </Grid>
                    <Grid item xs={12}>
                      <LabelValue label="Enseigne">{outletName}</LabelValue>
                    </Grid>
                    <Grid item xs={12}>
                      <LabelValue label="Zone de vente">{agency && agency.name}</LabelValue>
                    </Grid>
                    {!multiOutlet && area && area.name && (
                      <Grid item xs={12}>
                        <LabelValue label="Secteur">{area.name}</LabelValue>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {multiOutlet && (
              <Grid item xs={12} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Point de vente Origine</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <LabelValue label="Code">
                          {originOutlet.code && (
                            <Link component={RouterLink} to={`/outlets/${originOutlet.id}`}>
                              {originOutlet.code}
                            </Link>
                          )}
                        </LabelValue>
                      </Grid>
                      <Grid item xs={12}>
                        <LabelValue label="Enseigne">{originOutlet.name}</LabelValue>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <ResponsiveTabs onChangeIndex={this.onTabChange} value={activeTabIndex}>
              <ResponsiveTab icon={<FormatListChecks />} label={<div>Prestations</div>} value={0} />
              <ResponsiveTab icon={<Camera />} label={<div>Pièces jointes</div>} value={1} />
              <ResponsiveTab icon={<Pen />} label={<div>Signatures</div>} value={2} />
              <ResponsiveTab icon={<History />} label={<div>Historique</div>} value={3} />
              {showDeliveries && <ResponsiveTab icon={<Truck />} label={<div>Livraisons</div>} value={4} />}
            </ResponsiveTabs>
            <SwipeableViews
              index={activeTabIndex}
              onChangeIndex={this.onTabChange}
              animateHeight
              action={(actions) => {
                this.swipeableActions = actions;
              }}
            >
              {tabs}
            </SwipeableViews>
          </Card>
        </Grid>

        {id && status && (
          <Grid item xs={12} className={classNames(classes.actions, classes.stickyActionBar)}>
            <InterventionsButtons
              addOperations={addOperations}
              removeOperations={removeOperations}
              moveOperations={moveOperations}
              demandId={demandId}
              interventionId={id}
              interventionStatus={status}
              outletSignature={outletSignature}
              subcontractorSignature={subcontractorSignature}
              needsDeliveryDetails={canHaveDelivery}
              numberOfInterventions={demandInterventions}
              operationScheduleDate={operationScheduleDate}
              operationScheduleDayTime={operationScheduleDayTime}
              operationStartedDate={operationStartedDateProp}
              operationFinishedDate={operationFinishedDateProp}
              isAgency={isAgency}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

const stateToProps = ({
  intervention,
  interventionOperations: { operations },
  interventionSignatures: signatures,
  currentUser,
  users: { users },
  demandTypes,
}) => ({
  intervention,
  operations,
  signatures,
  currentUser,
  users,
  hasRealEquipments: hasNonDummyEquipments(operations.addOperations),
  demandTypes,
});

export default compose(
  withStyles(styles),
  connect(stateToProps),
  withPageTitle(({ intervention: { intervention } }) => `Intervention ${intervention?.number ?? ''}`),
)(Intervention);

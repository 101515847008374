import {
  STOCK_EQUIPMENT_ERROR,
  STOCK_EQUIPMENT_LOADED,
  STOCK_EQUIPMENT_LOADING,
  STOCK_EQUIPMENT_REFRESH_ERROR,
  urls,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalError, addGlobalMessage } from './SnackbarActions';
import history from '../history';
import { StockBatchElement, StockEquipments } from '../model/model';
import { AppDispatch, RootState } from '../store';
import { IUpdateInputsWithError } from 'formsy-react/src/interfaces';

export const stockEquipmentExportUrl = (stockOutletCodes: string[]) => {
  const baseUrlExport = api.one(urls.stockEquipments, stockOutletCodes.join(',')).url();
  return `${baseUrlExport}/export.csv`;
};

export function resetStockEquipment() {
  return {
    type: STOCK_EQUIPMENT_LOADED,
    stockOutletCode: null,
    stockOutletName: null,
    stockEquipments: [],
    pausedInventoryDate: null,
  };
}

export function loadStockEquipment(stockOutletCode: string) {
  return (dispatch: AppDispatch, state: RootState) => {
    if (state.stockEquipments?.stockOutletCode !== stockOutletCode) {
      dispatch(resetStockEquipment());
    }
    dispatch({
      type: STOCK_EQUIPMENT_LOADING,
      stockOutletCode,
    });
    api
      .all(urls.stockEquipments)
      .get(stockOutletCode)
      .then((response) => {
        const { stockOutletName, stockEquipments, pausedInventoryDate }: StockEquipments = response.body(false);
        dispatch({
          type: STOCK_EQUIPMENT_LOADED,
          stockOutletCode,
          stockOutletName,
          stockEquipments,
          pausedInventoryDate,
        });
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while loading the stock equipment:', error);
        dispatch({
          type: STOCK_EQUIPMENT_ERROR,
          error,
        });
      });
  };
}

export function refreshStockEquipment(stockOutletCode: string) {
  return (dispatch: AppDispatch, state: RootState) => {
    if (state.stockEquipments?.stockOutletCode !== stockOutletCode) {
      dispatch(resetStockEquipment());
    }
    dispatch({
      type: STOCK_EQUIPMENT_LOADING,
      stockOutletCode,
    });
    api
      .one(urls.stockEquipments, stockOutletCode)
      .custom('refresh')
      .post()
      .then(() => {
        dispatch(loadStockEquipment(stockOutletCode));
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while refreshing stock equipments:', error);
        dispatch({
          type: STOCK_EQUIPMENT_REFRESH_ERROR,
          error,
        });
      });
  };
}

export function addEquipmentsToStock(
  stockOutletCode: string,
  equipmentBatchElementList: StockBatchElement[],
  invalidateForm: IUpdateInputsWithError,
) {
  return (dispatch: AppDispatch) => {
    api
      .one(urls.stockEquipments, stockOutletCode)
      .all('reception')
      .post(equipmentBatchElementList)
      .then(() => {
        history.push({ pathname: `/stock-equipments/${stockOutletCode}` });
        dispatch(addGlobalMessage('La réception de matériel a été enregistrée'));
      })
      .catch(ignore401)
      .catch((err) => {
        if (err.response?.statusCode === 404 && err.response.data?.message?.startsWith('Intervention')) {
          invalidateForm({ interventionNumber: 'Intervention non trouvée' });
          dispatch(addGlobalError('Intervention non trouvée'));
        } else {
          console.error('Error while saving stock equipments:', err.response);
          dispatch(addGlobalError("Erreur pendant l'enregistrement"));
        }
      });
  };
}

export function prepareRemovalEquipment(
  stockOutletCode: String,
  stockEquipments: StockBatchElement[],
  targetStockCode: string,
  batchId: string | undefined,
  send: boolean,
) {
  const action = send ? 'removal-send' : 'removal';
  return (dispatch: AppDispatch) => {
    const request = batchId
      ? api.one(urls.stockEquipments, stockOutletCode).one(action, batchId).put({
          stockEquipments,
          targetStockCode,
        })
      : api.one(urls.stockEquipments, stockOutletCode).all(action).post({
          stockEquipments,
          targetStockCode,
        });
    request
      .then(() => {
        history.push({ pathname: `/stock-equipments/${stockOutletCode}` });
        dispatch(
          addGlobalMessage(
            send ? 'La sortie de stock a bien été enregistrée' : 'La préparation de lot de sortie a été enregistrée',
          ),
        );
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while saving stock equipments:', err);
        dispatch(addGlobalError("Erreur pendant l'enregistrement"));
      });
  };
}

export function deleteRemovalBatch(stockOutletCode: String, batchId: string) {
  return (dispatch: AppDispatch) => {
    api
      .one(urls.stockEquipments, stockOutletCode)
      .one('removal', batchId)
      .delete()
      .then(() => {
        history.push({ pathname: `/stock-equipments/${stockOutletCode}` });
        dispatch(addGlobalMessage('La préparation a été supprimée'));
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while deleting batch:', err);
        dispatch(addGlobalError("Erreur pendant l'enregistrement"));
      });
  };
}

export function sendRemovalBatch(stockOutletCode: String, batchId: string) {
  return (dispatch: AppDispatch) => {
    api
      .one(urls.stockEquipments, stockOutletCode)
      .one('removal', batchId)
      .custom('send')
      .post()
      .then(() => {
        history.push({ pathname: `/stock-equipments/${stockOutletCode}` });
        dispatch(addGlobalMessage('La sortie de stock a bien été enregistrée'));
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while sending batch:', err);
        dispatch(addGlobalError("Erreur pendant l'enregistrement"));
      });
  };
}

import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Tooltip } from '@mui/material';
import { Plus } from 'mdi-material-ui';
import { connect } from 'react-redux';
import { checkCanCreateIntervention } from '../../services/WorkFlowService';
import InterventionCreationDialog from './InterventionCreationDialog';

class InterventionCreation extends React.Component {
  static propTypes = {
    demandId: PropTypes.string.isRequired,
    demandNumber: PropTypes.number.isRequired,
    demandStatus: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  };

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  onClose = () => {
    this.setState({ opened: false });
  };

  onOpen = () => {
    this.setState({ opened: true });
  };

  render() {
    const { canCreate, demandId, demandNumber, demandStatus, dispatch } = this.props;

    if (!canCreate) {
      return null;
    }

    if (demandStatus === '_045_TO_RESCHEDULE') {
      return (
        <Tooltip title='Pour créer une intervention, faites "Reprogrammer" depuis l&apos;intervention'>
          <span>
            <Button variant="contained" color="primary" disabled startIcon={<Plus />}>
              Créer une intervention
            </Button>
          </span>
        </Tooltip>
      );
    }

    return (
      <>
        <Button variant="contained" onClick={this.onOpen} color="primary" startIcon={<Plus />}>
          Créer une intervention
        </Button>
        <InterventionCreationDialog
          demandId={demandId}
          dispatch={dispatch}
          opened={this.state.opened}
          demandNumber={demandNumber}
          onClose={this.onClose}
        />
      </>
    );
  }
}

export default connect((state, ownProps) => ({
  canCreate: checkCanCreateIntervention(state.currentUser, ownProps.demandStatus),
}))(InterventionCreation);

import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Delete } from 'mdi-material-ui';

import { deleteUser } from '../../actions/UserActions';

class UserDeletionDialog extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  state = {
    opened: false,
  };

  onOpenToggle = () => {
    this.setState(({ opened }) => ({
      opened: !opened,
    }));
  };

  onConfirm = () => {
    this.setState({
      opened: false,
    });
    this.props.dispatch(deleteUser(this.props.id));
  };

  render() {
    return (
      <div>
        <Button variant="contained" onClick={this.onOpenToggle} color="error" startIcon={<Delete />}>
          Supprimer
        </Button>
        <Dialog open={this.state.opened} onClose={this.onOpenToggle}>
          <DialogContent>
            <DialogContentText>Êtes-vous sûr de vouloir supprimer l'utilisateur {this.props.email} ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onOpenToggle}>Annuler</Button>
            <Button color="secondary" onClick={this.onConfirm}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UserDeletionDialog;

import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Download } from 'mdi-material-ui';

import { interventionExportUrl } from '../../actions/InterventionActions';
import { paginationToParams } from '../../utils/paging';
import { downloadUrl } from '../../actions/DownloadActions';

const styles = (theme) => ({
  buttonLabel: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

class InterventionsExport extends Component {
  render() {
    const { pagination, dispatch, classes } = this.props;
    const params = paginationToParams(pagination);
    delete params.page;
    delete params.size;

    const urlParam = params.filter
      ? '?' + params.filter.map((filter) => `filter%5B%5D=${encodeURIComponent(filter)}`).join('&')
      : '';
    const url = interventionExportUrl + urlParam;

    return (
      !!pagination.totalElements && (
        <Button onClick={() => dispatch(downloadUrl(url, 'Interventions.xlsx'))}>
          <Download />
          <span className={classes.buttonLabel}>Exporter les interventions</span>
        </Button>
      )
    );
  }
}

const stateToProps = ({ interventions: { pagination } }) => ({
  pagination,
});

export default compose(withStyles(styles), connect(stateToProps))(InterventionsExport);

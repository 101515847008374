/*
 * AppConstants
 * These are the variables that determine what our central data store (reducer.js)
 * changes in our state. When you add a new action, you have to add a new constant here
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */
import React from 'react';
import {
  Account,
  AccountOutline,
  Alert,
  AlertCircle,
  Cancel,
  Check,
  CheckCircle,
  Clock,
  ClockFast,
  CloseCircle,
} from 'mdi-material-ui';
import { pmuGreen } from '../components/utils/colors';

import Logo from '../components/utils/Logo';
import FilRougeLogo from '../img/company/filrouge.png';
import HomeConceptLogo from '../img/company/homeconcept.png';
import LabbeLogo from '../img/company/labbe.png';
import PmuLogo from '../img/company/pmu.png';
import RougnonLogo from '../img/company/rougnon.png';
import SpieLogo from '../img/company/spie.png';
import TibcoLogo from '../img/company/tibco.png';
import GreenDebarrasLogo from '../img/company/greendebarras.png';
import RouxLogo from '../img/company/roux.png';
import EurodislogLogo from '../img/company/eurodislog.png';
import CarrusLogo from '../img/company/carrus.png';
import DiapasonLogo from '../img/company/diapason.png';
import PmcLogo from '../img/company/pmc.png';
import AtfGaiaLogo from '../img/company/atf-gaia.png';
import {
  Answer,
  Company,
  Concept,
  ContractStatus,
  CostStructure,
  DayTime,
  DemandType,
  EquipmentMoveSendError,
  EquipmentMoveSendStatus,
  EquipmentSerialNumberType,
  EquipmentState,
  EquipmentType,
  FDDProduct,
  FDDStatus,
  FDDStatusId,
  IdName,
  InterventionType,
  OperationType,
  OracleCommandStatus,
  OutletType,
  Question,
  UserStatus,
} from '../model/model';

export const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

/*
 * Global actions
 */
export const MAIN_MENU_TOGGLE = 'MAIN_MENU_TOGGLE';
export const MAIN_MENU_CLOSE = 'MAIN_MENU_CLOSE';
export const DISPLAY_LOADER = 'DISPLAY_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const ADD_GLOBAL_MESSAGE = 'ADD_GLOBAL_MESSAGE';
export const DISMISS_GLOBAL_MESSAGE = 'DISMISS_GLOBAL_MESSAGE';

/*
 * Security actions
 */
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const CURRENT_USER_LOADED = 'CURRENT_USER_LOADED';
export const LOGOUT = 'LOGOUT';

/*
 * Security constants
 */
export const CURRENT_USER = 'currentUser';

/*
 * Demands actions
 */
export const DEMANDS_FILTER_UPDATED = 'DEMANDS_FILTER_UPDATED';
export const DEMANDS_LOADED = 'DEMANDS_LOADED';

export const DEMAND_LOADED = 'DEMAND_LOADED';
export const DEMAND_SAVED = 'DEMAND_SAVED';
export const DEMAND_RESET = 'DEMAND_RESET';
export const DEMAND_ERROR = 'DEMAND_ERROR';
export const DEMAND_VALIDATION_ERROR = 'DEMAND_VALIDATION_ERROR';

export const DEMAND_OPERATIONS_LOADED = 'DEMAND_OPERATIONS_LOADED';
export const DEMAND_OPERATIONS_RESET = 'DEMAND_OPERATIONS_RESET';
export const DEMAND_OPERATIONS_ERROR = 'DEMAND_OPERATIONS_ERROR';
export const DEMAND_OPERATION_COMPLETION_UPDATE = 'DEMAND_OPERATION_COMPLETION_UPDATE';

export const DEMAND_ATTACHMENTS_LIST = 'DEMAND_ATTACHMENTS_LIST';
export const DEMAND_ATTACHMENTS_ITEM = 'DEMAND_ATTACHMENTS_ITEM';
export const DEMAND_ATTACHMENTS_DELETE = 'DEMAND_ATTACHMENTS_DELETE';

export const DEMAND_COST_LOADED = 'DEMAND_COST_LOADED';
export const DEMAND_COST_CLOSE = 'DEMAND_COST_CLOSE';
export const DEMAND_COST_UPDATED = 'DEMAND_COST_UPDATED';

export const DEMAND_UPDATE_INVOICE = 'DEMAND_UPDATE_INVOICE';

/*
 * Demand Types actions
 */

export const DEMAND_TYPES_LOADED = 'DEMAND_TYPES_LOADED';
export const DEMAND_TYPE_ADDED = 'DEMAND_TYPE_ADDED';
export const DEMAND_TYPE_SAVED = 'DEMAND_TYPE_SAVED';
export const DEMAND_TYPE_DELETED = 'DEMAND_TYPE_DELETED';

/*
 * Delivery locations actions
 */

export const DELIVERY_LOCATIONS_LOADED = 'DELIVERY_LOCATIONS_LOADED';
export const DELIVERY_LOCATION_ADDED = 'DELIVERY_LOCATION_ADDED';
export const DELIVERY_LOCATION_SAVED = 'DELIVERY_LOCATION_SAVED';
export const DELIVERY_LOCATION_DELETED = 'DELIVERY_LOCATION_DELETED';

/*
 * Demands transitions actions
 */
export const NEW_TRANSITION_ATTACHMENT_UPLOADING = 'NEW_TRANSITION_ATTACHMENT_UPLOADING';
export const NEW_TRANSITION_ATTACHMENT_UPLOADED = 'NEW_TRANSITION_ATTACHMENT_UPLOADED';
export const TRANSITION_ATTACHMENT_DELETED = 'TRANSITION_ATTACHMENT_DELETED';

/*
 * Intervention actions
 */
export const INTERVENTIONS_FILTER_UPDATED = 'INTERVENTIONS_FILTER_UPDATED';
export const INTERVENTIONS_LOADED = 'INTERVENTIONS_LOADED';
export const INTERVENTIONS_NEED_FILTER = 'INTERVENTIONS_NEED_FILTER';

export const INTERVENTION_LOADING = 'INTERVENTION_LOADING';
export const INTERVENTION_LOADED = 'INTERVENTION_LOADED';
export const INTERVENTION_SAVED = 'INTERVENTION_SAVED';
export const INTERVENTION_RESET = 'INTERVENTION_RESET';
export const INTERVENTION_ERROR = 'INTERVENTION_ERROR';

export const INTERVENTION_ATTACHMENTS_DELETE = 'INTERVENTION_ATTACHMENTS_DELETE';
export const INTERVENTION_ATTACHMENTS_ITEM = 'INTERVENTION_ATTACHMENTS_ITEM';
export const INTERVENTION_ATTACHMENTS_LIST = 'INTERVENTION_ATTACHMENTS_LIST';

export const INTERVENTION_OPERATIONS_THAT_CAN_BE_ADDED_LIST = 'INTERVENTION_OPERATIONS_THAT_CAN_BE_ADDED_LIST';
export const INTERVENTION_OPERATIONS = 'INTERVENTION_OPERATIONS';
export const INTERVENTION_OPERATION_ADDED = 'INTERVENTION_OPERATION_ADDED';
export const INTERVENTION_OPERATION_UPDATE_COMPLETED_QUANTITY = 'INTERVENTION_OPERATION_TOGGLE_COMPLETION';
export const INTERVENTION_OPERATION_UPDATE_QUANTITY = 'INTERVENTION_OPERATION_UPDATE_QUANTITY';

export const INTERVENTION_SIGNATURES = 'INTERVENTION_SIGNATURES';
export const INTERVENTION_SIGNATURE_OUTLET = 'INTERVENTION_SIGNATURE_OUTLET';
export const INTERVENTION_SIGNATURE_SUBCONTRACTOR = 'INTERVENTION_SIGNATURE_SUBCONTRACTOR';

export const INTERVENTION_COMMAND_ADDED = 'INTERVENTION_COMMAND_ADDED';
export const INTERVENTION_COMMAND_UPDATED = 'INTERVENTION_COMMAND_UPDATED';

/*
 * Outlet actions
 */
export const OUTLET_AUTO_COMPLETE = 'OUTLET_AUTO_COMPLETE';

export const OUTLETS_FILTER_UPDATED = 'OUTLETS_FILTER_UPDATED';
export const OUTLETS_LOADED = 'OUTLETS_LOADED';

export const OUTLET_LOADED = 'OUTLET_LOADED';
export const OUTLET_SAVED = 'OUTLET_SAVED';
export const OUTLET_RESET = 'OUTLET_RESET';
export const OUTLET_ERROR = 'OUTLET_ERROR';

/*
 * Profile actions
 */
export const PROFILES_LOAD_SUCCESS = 'PROFILES_LOAD_SUCCESS';
export const PROFILES_LOAD_ERROR = 'PROFILES_LOAD_ERROR';
export const PROFILES_LIST_UPDATE = 'PROFILES_LIST_UPDATE';
export const PROFILES_LIST_DELETE = 'PROFILES_LIST_DELETE';
export const PROFILES_LIST_SORT = 'PROFILES_LIST_SORT';
export const PROFILE_EDIT_START = 'PROFILE_EDIT_START';
export const PROFILE_EDIT_CANCEL = 'PROFILE_EDIT_CANCEL';
export const PROFILE_EDIT_ERROR = 'PROFILE_EDIT_ERROR';
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS';

/*
 * User actions
 */
export const USERS_LOAD = 'USERS_LOAD';
export const USERS_FILTER_AND_SORT = 'USERS_FILTER_AND_SORT';
export const USER_LOAD = 'USER_LOAD';
export const USER_CARD_LEAVE = 'USER_CARD_LEAVE';
export const USER_TOGGLE_EDIT = 'USER_TOGGLE_EDIT';
export const USER_ERROR = 'USER_ERROR';

/*
 * Settings actions
 */
export const REGIONS_LOADED = 'REGIONS_LOADED';
export const REGION_ADDED = 'REGION_ADDED';
export const REGION_SAVED = 'REGION_SAVED';
export const REGION_DELETED = 'REGION_DELETED';

export const AGENCIES_LOADED = 'AGENCIES_LOADED';
export const AGENCIES_LIST_SORT = 'AGENCIES_LIST_SORT';
export const AGENCY_ADDED = 'AGENCY_ADDED';
export const AGENCY_SAVED = 'AGENCY_SAVED';
export const AGENCY_DELETED = 'AGENCY_DELETED';

export const AREAS_LOADED = 'AREAS_LOADED';
export const AREAS_LIST_SORT = 'AREAS_LIST_SORT';
export const AREA_ADDED = 'AREA_ADDED';
export const AREA_SAVED = 'AREA_SAVED';
export const AREA_DELETED = 'AREA_DELETED';

/*
 * Equipments actions
 */
export const EQUIPMENTS_LOADED = 'EQUIPMENTS_LOADED';
export const SORT_EQUIPMENTS = 'SORT_EQUIPMENTS';
export const EQUIPMENT_LOADED = 'EQUIPMENT_LOADED';
export const EQUIPMENT_LOADING = 'EQUIPMENT_LOADING';
export const EQUIPMENT_ERROR = 'EQUIPMENT_ERROR';
export const EQUIPMENT_RESET = 'EQUIPMENT_RESET';
export const EQUIPMENT_DELETION_ERRORS = 'EQUIPMENT_DELETION_ERRORS';
export const EQUIPMENT_VALIDATION_ERRORS = 'EQUIPMENT_VALIDATION_ERRORS';

/*
 * Operation bundles actions
 */
export const OPERATION_BUNDLES_LOADED = 'OPERATION_BUNDLES_LOADED';
export const OPERATION_BUNDLES_SORT = 'OPERATION_BUNDLES_SORT';
export const OPERATION_BUNDLE_LOADED = 'OPERATION_BUNDLE_LOADED';
export const OPERATION_BUNDLE_LOADING = 'OPERATION_BUNDLE_LOADING';
export const OPERATION_BUNDLE_ERROR = 'OPERATION_BUNDLE_ERROR';
export const OPERATION_BUNDLE_RESET = 'OPERATION_BUNDLE_RESET';
export const OPERATION_BUNDLE_DELETION_ERRORS = 'OPERATION_BUNDLE_DELETION_ERRORS';
export const OPERATION_BUNDLE_VALIDATION_ERRORS = 'OPERATION_BUNDLE_VALIDATION_ERRORS';
export const OPERATION_BUNDLE_OPTIONS_LOADING = 'OPERATION_BUNDLE_OPTIONS_LOADING';
export const OPERATION_BUNDLE_OPTIONS_LOADED = 'OPERATION_BUNDLE_OPTIONS_LOADED';
export const OPERATION_BUNDLE_OPTIONS_RESET = 'OPERATION_BUNDLE_OPTIONS_RESET';

/*
 * After sales operations actions
 */
export const AFTER_SALES_OPERATION_LOADED = 'AFTER_SALES_OPERATION_LOADED';
export const AFTER_SALES_OPERATION_LOADING = 'AFTER_SALES_OPERATION_LOADING';
export const AFTER_SALES_OPERATION_RESET = 'AFTER_SALES_OPERATION_RESET';
export const AFTER_SALES_OPERATION_ERROR = 'AFTER_SALES_OPERATION_ERROR';
export const AFTER_SALES_OPERATIONS_LOADED = 'AFTER_SALES_OPERATIONS_LOADED';
export const AFTER_SALES_OPERATION_UPDATING_POSITION = 'AFTER_SALES_OPERATION_UPDATING_POSITION';
export const AFTER_SALES_OPERATION_DELETION_ERRORS = 'AFTER_SALES_OPERATION_DELETION_ERRORS';
export const AFTER_SALES_OPERATION_VALIDATION_ERRORS = 'AFTER_SALES_OPERATION_VALIDATION_ERRORS';

/*
 * Operation options actions
 */
export const OPERATION_OPTION_DELETION_ERRORS = 'OPERATION_OPTION_DELETION_ERRORS';
export const OPERATION_OPTION_ERROR = 'OPERATION_OPTION_ERROR';
export const OPERATION_OPTION_LOADED = 'OPERATION_OPTION_LOADED';
export const OPERATION_OPTION_LOADING = 'OPERATION_OPTION_LOADING';
export const OPERATION_OPTION_RESET = 'OPERATION_OPTION_RESET';
export const OPERATION_OPTION_VALIDATION_ERRORS = 'OPERATION_OPTION_VALIDATION_ERRORS';
export const OPERATION_OPTIONS_LOADED = 'OPERATION_OPTIONS_LOADED';
export const OPERATION_OPTIONS_UPDATING_POSITION = 'OPERATION_OPTIONS_UPDATING_POSITION';

/*
 * Operation groups actions
 */
export const OPERATION_GROUPS_LOADED = 'OPERATION_GROUPS_LOADED';

export const OUTLETS_IMPORTS_LOADED = 'OUTLETS_IMPORT_JOBS_LOADED';
export const DEMAND_IMPORTS_LOADING = 'DEMAND_IMPORTS_LOADING';
export const DEMAND_IMPORTS_LOADED = 'DEMAND_IMPORTS_LOADED';
export const DEMAND_IMPORTS_RESET = 'DEMAND_IMPORTS_RESET';

/*
 * Reload demand costs actions
 */
export const RELOAD_DEMAND_COSTS_STATUS_LOADED = 'RELOAD_DEMAND_COSTS_STATUS_LOADED';

/*
 * Equipment Move actions
 */
export const EQUIPMENT_MOVE_FILTER_UPDATED = 'EQUIPMENT_MOVE_FILTER_UPDATED';
export const EQUIPMENT_MOVE_LOADED = 'EQUIPMENT_MOVE_LOADED';
export const EQUIPMENT_MOVE_UPDATED = 'EQUIPMENT_MOVE_UPDATED';

/*
 * FDD actions
 */
export const FDDS_FILTER_UPDATED = 'FDDS_FILTER_UPDATED';
export const FDDS_LOADED = 'FDDS_LOADED';

export const FDD_LOADED = 'FDD_LOADED';
export const FDD_TOTAL_LOADED = 'FDD_TOTAL_LOADED';
export const FDD_RESET = 'FDD_RESET';
export const FDD_LOADING = 'FDD_LOADING';
export const FDD_ERROR = 'FDD_ERROR';
export const FDD_VALIDATION_ERRORS = 'FDD_VALIDATION_ERRORS';

export const FDD_ATTACHMENT_SET = 'FDD_ATTACHMENT_SET';
export const FDD_ATTACHMENT_DELETE = 'FDD_ATTACHMENT_DELETE';

export const FDD_GOALS_LOADED = 'FDD_GOALS_LOADED';
export const FDD_GOAL_ADDED = 'FDD_GOAL_ADDED';
export const FDD_GOAL_SAVED = 'FDD_GOAL_SAVED';
export const FDD_GOAL_DELETED = 'FDD_GOAL_DELETED';

export const FDD_SUB_GOALS_LOADED = 'FDD_SUB_GOALS_LOADED';
export const FDD_SUB_GOAL_SAVED = 'FDD_SUB_GOAL_SAVED';
export const FDD_SUB_GOAL_DELETED = 'FDD_SUB_GOAL_DELETED';

export const FDD_AGREEMENT_TYPES_LOADED = 'FDD_AGREEMENT_TYPES_LOADED';
export const FDD_AGREEMENT_TYPE_ADDED = 'FDD_AGREEMENT_TYPE_ADDED';
export const FDD_AGREEMENT_TYPE_SAVED = 'FDD_AGREEMENT_TYPE_SAVED';
export const FDD_AGREEMENT_TYPE_DELETED = 'FDD_AGREEMENT_TYPE_DELETED';

export const FDD_THRESHOLDS_LOADED = 'FDD_THRESHOLDS_LOADED';
export const FDD_THRESHOLD_ADDED = 'FDD_THRESHOLD_ADDED';
export const FDD_THRESHOLD_SAVED = 'FDD_THRESHOLD_SAVED';
export const FDD_THRESHOLD_DELETED = 'FDD_THRESHOLD_DELETED';

export const FDD_SECONDARY_THRESHOLDS_LOADED = 'FDD_SECONDARY_THRESHOLDS_LOADED';
export const FDD_SECONDARY_THRESHOLD_SAVED = 'FDD_SECONDARY_THRESHOLD_SAVED';

export const DAY_ACTIVITY_FILTER_UPDATED = 'DAY_ACTIVITY_FILTER_UPDATED';
export const DAY_ACTIVITY_LOADED = 'DAY_ACTIVITY_LOADED';

export const STOCK_EQUIPMENT_LOADING = 'STOCK_EQUIPMENT_LOADING';
export const STOCK_EQUIPMENT_LOADED = 'STOCK_EQUIPMENT_LOADED';
export const STOCK_EQUIPMENT_ERROR = 'STOCK_EQUIPMENT_ERROR';
export const STOCK_EQUIPMENT_REFRESH_ERROR = 'STOCK_EQUIPMENT_REFRESH_ERROR';

export const STOCK_REMOVAL_TARGETS_LOADED = 'STOCK_REMOVAL_TARGETS_LOADED';

/*
 * API
 */
export const apiBase = '/api';

export const urls = {
  deliveryLocations: 'delivery-locations',
  demands: 'demands',
  demandOperations: 'demands/operations',
  demandTransitions: 'demands/transitions',
  demandCost: 'demands/cost',
  demandTypes: 'demand-types',
  demandImport: 'demands/import',
  demandInvoicesImport: 'demands/invoices-import',
  interventions: 'interventions',
  dayActivity: 'interventions/day-activity',
  outlets: 'outlets',
  outletsImport: 'jobs/outlets-imports',
  outletAutoCompletion: 'outlets/by-code',
  outletByCode: 'outlets/code',
  equipmentMoves: 'equipment-moves',
  stockEquipments: 'stock-equipments',
  stockEquipmentBySerialNumber: 'stock-equipments/by-serial-number',
  stockRemovalTargets: 'stock-equipments/targets',
  fdd: {
    list: 'fdd',
    agreementTypes: 'fdd/agreement-types',
    goals: 'fdd/goals',
    subGoals: 'fdd/sub-goals',
    thresholds: 'fdd/thresholds',
    secondaryThresholds: 'fdd/secondary-thresholds',
    transition: 'fdd/transition',
    total: 'fdd/total-by-outlet',
  },
  user: {
    login: 'user',
    logout: 'logout',
    profiles: 'profiles',
  },
  sentry: 'sentry',
  settings: {
    regions: 'regions',
    agencies: 'agencies',
    areas: 'areas',
    equipments: 'equipments',
    bundles: 'operation-bundles',
    afterSalesOperations: 'after-sales-operations',
    options: 'operation-options',
    operationGroups: 'operation-groups',
    reloadDemandCosts: 'reload-demand-costs',
    addToDemands: 'add-to-demands',
  },
};

export type DecoratedArray<T, ID = string> = T[] & {
  getById: (id: ID) => T;
  getNameById: (id: ID, getDefaultName?: (id: ID) => string) => string;
};

export const getByIdDecorator = <T extends IdName<ID>, ID = string>(array: T[]): DecoratedArray<T> => {
  const decoratedArray = array as DecoratedArray<T>;
  decoratedArray.getById = (id) => array.find((c) => c.id === id);
  decoratedArray.getNameById = (id, getDefaultName = () => '') =>
    (decoratedArray.getById(id) ?? { name: getDefaultName(id) }).name;
  return decoratedArray;
};

/**
 * Companies
 */
export const COMPANIES = getByIdDecorator<Company>([
  {
    id: 'PMU',
    name: 'PMU',
    isSubcontractor: false,
    isActive: true,
    isInstaller: true,
    icon: <Logo image={PmuLogo} />,
    pieColor: pmuGreen,
  },
  {
    id: 'FIL_ROUGE',
    name: 'Fil Rouge',
    isSubcontractor: true,
    isActive: false,
    isInstaller: true,
    icon: <Logo image={FilRougeLogo} />,
    pieColor: '#A81114',
  },
  {
    id: 'ROUGNON',
    name: 'Rougnon',
    isSubcontractor: true,
    isActive: true,
    isInstaller: true,
    icon: <Logo image={RougnonLogo} />,
    pieColor: '#FCCF87',
  },
  {
    id: 'SPIE',
    name: 'SPIE',
    isSubcontractor: true,
    isActive: false,
    isInstaller: true,
    icon: <Logo image={SpieLogo} />,
    pieColor: '#1C2E5F',
  },
  {
    id: 'LABBE',
    name: 'Groupe LABBE',
    isSubcontractor: true,
    isActive: true,
    isInstaller: true,
    icon: <Logo image={LabbeLogo} />,
    pieColor: '#399ED8',
  },
  {
    id: 'HOME_CONCEPT',
    name: 'Home Concept',
    isSubcontractor: true,
    isActive: false,
    isInstaller: true,
    icon: <Logo image={HomeConceptLogo} />,
    pieColor: '#000000',
  },
  {
    id: 'TIBCO',
    name: 'TIBCO',
    isSubcontractor: true,
    isActive: false,
    isInstaller: true,
    icon: <Logo image={TibcoLogo} />,
    pieColor: '#FF9301',
  },
  {
    id: 'GREEN_DEBARRAS',
    name: 'Green Débarras',
    isSubcontractor: true,
    isActive: false,
    isInstaller: true,
    icon: <Logo image={GreenDebarrasLogo} />,
    pieColor: '#99DA19',
  },
  {
    id: 'PMC',
    name: 'PMC',
    isSubcontractor: true,
    isActive: true,
    isInstaller: false,
    icon: <Logo image={PmcLogo} />,
    pieColor: '#f1bbcb',
  },
  {
    id: 'CARRUS_GAMING',
    name: 'Carrus Gaming',
    isSubcontractor: true,
    isActive: true,
    isInstaller: false,
    icon: <Logo image={CarrusLogo} />,
    pieColor: '#99DA19',
  },
  {
    id: 'ROUX',
    name: 'ROUX',
    isSubcontractor: true,
    isActive: true,
    isInstaller: false,
    icon: <Logo image={RouxLogo} />,
    pieColor: '#987e61',
  },
  {
    id: 'EURODISLOG',
    name: 'Eurodislog',
    isSubcontractor: true,
    isActive: true,
    isInstaller: false,
    icon: <Logo image={EurodislogLogo} />,
    pieColor: '#19daad',
  },
  {
    id: 'DIAPASON',
    name: 'Diapason',
    isSubcontractor: true,
    isActive: true,
    isInstaller: false,
    icon: <Logo image={DiapasonLogo} />,
    pieColor: '#b019da',
  },
  {
    id: 'ATF_GAIA',
    name: 'ATF GAIA',
    isSubcontractor: false,
    isActive: true,
    isInstaller: false,
    icon: <Logo image={AtfGaiaLogo} />,
    pieColor: '#19a3da',
  },
])
  .sort((a, b) => a.name.localeCompare(b.name))
  .sort((a, b) => Number(b.isSubcontractor) - Number(a.isSubcontractor))
  .sort((a, b) => Number(b.isActive) - Number(a.isActive));

export const ACTIVE_COMPANIES = COMPANIES.filter((company) => company.isActive);
export const INSTALLERS = COMPANIES.filter((company) => company.isInstaller);
export const ACTIVE_INSTALLERS = ACTIVE_COMPANIES.filter((company) => company.isInstaller);

/**
 * Concepts
 */
export const CONCEPTS = getByIdDecorator<Concept>([
  {
    id: 'ANCIEN_CONCEPT',
    name: 'Anciens merchs',
    isActive: true,
  },
  {
    id: 'NOUVEAU_CONCEPT',
    name: 'Merch 2022',
    isActive: true,
  },
  {
    id: 'PASSION_1',
    name: 'Passion 1',
    isActive: false,
  },
  {
    id: 'PASSION_2',
    name: 'Passion 2',
    isActive: false,
  },
  {
    id: 'PASSION_3',
    name: 'Passion 3',
    isActive: false,
  },
  {
    id: 'HAPPY',
    name: 'Happy',
    isActive: false,
  },
  {
    id: 'EXPRESS_1',
    name: 'Express 1',
    isActive: false,
  },
  {
    id: 'EXPRESS_2',
    name: 'Express 2',
    isActive: false,
  },
  {
    id: 'EXPRESS_3',
    name: 'Express 3',
    isActive: false,
  },
]).sort((a, b) => Number(b.isActive) - Number(a.isActive));

/**
 * Demand status
 */
export const DEMAND_STATUSES = getByIdDecorator<DemandType>([
  { id: '_010_EDITING', name: 'En cours de saisie' },
  { id: '_015_REJECTED', name: 'Refusée par le prestataire' },
  { id: '_020_AWAITING_PMU_VALIDATION', name: 'En attente de validation PMU' },
  { id: '_030_VALID', name: 'Validée' },
  { id: '_040_OPERATIONS_PROCESSING', name: 'En traitement prestataire' },
  { id: '_045_TO_RESCHEDULE', name: 'À reprogrammer' },
  { id: '_050_OPERATIONS_SCHEDULED', name: 'Planifiée' },
  { id: '_060_OPERATIONS_ON_GOING', name: 'En cours de réalisation prestataire' },
  { id: '_070_AWAITING_PMU_ACCEPTANCE', name: 'En attente de réception PMU' },
  { id: '_075_REJECTED_RESERVE', name: 'Réserves non valables' },
  { id: '_080_ACCEPTED_WITH_RESERVE', name: 'Réceptionnée avec réserves' },
  { id: '_090_OPERATIONS_RESERVE_REMOVING', name: 'En cours de levée prestataire' },
  { id: '_100_DELETED', name: 'Supprimée' },
  { id: '_110_CANCELLED', name: 'Annulée' },
  { id: '_120_ACCEPTED_WITHOUT_RESERVE', name: 'Réceptionnée sans réserves' },
]);

export const INTERVENTION_STATUS_COLORS = {
  grey: '#4F4F4F',
  green: '#007C62',
  yellow: '#F9B000',
  red: '#A11A16',
};

/**
 * Intervention status
 */
export const INTERVENTION_STATUSES = getByIdDecorator<InterventionType>([
  {
    id: '_010_CREATED',
    name: 'En cours de traitement',
    final: false,
    color: INTERVENTION_STATUS_COLORS.grey,
    pieColor: '#4F4F4F',
  },
  {
    id: '_020_SCHEDULED',
    name: 'Planifiée',
    final: false,
    color: INTERVENTION_STATUS_COLORS.yellow,
    pieColor: '#F9B000',
  },
  {
    id: '_030_CANCELLED',
    name: 'Annulée à reprogrammer',
    final: true,
    color: INTERVENTION_STATUS_COLORS.red,
    pieColor: '#A11A16',
  },
  {
    id: '_040_CANCELLED_SCHEDULED',
    name: 'Annulée reprogrammée',
    final: true,
    color: INTERVENTION_STATUS_COLORS.grey,
    pieColor: '#009FE3',
  },
  {
    id: '_050_UNFINISHED',
    name: 'Inachevée à reprogrammer',
    final: true,
    color: INTERVENTION_STATUS_COLORS.red,
    pieColor: '#EA580C',
  },
  {
    id: '_060_UNFINISHED_SCHEDULED',
    name: 'Inachevée reprogrammée',
    final: true,
    color: INTERVENTION_STATUS_COLORS.grey,
    pieColor: '#004D9D',
  },
  {
    id: '_070_FINISHED',
    name: 'Terminée',
    final: true,
    color: INTERVENTION_STATUS_COLORS.green,
    pieColor: '#00692C',
  },
]);

/**
 * Oracle command status
 */
export const ORACLE_COMMAND_STATUSES = getByIdDecorator<OracleCommandStatus>([
  {
    id: 'NO_COMMAND',
    name: 'Sans commande',
    icon: <CloseCircle style={{ verticalAlign: 'inherit' }} />,
  },
  {
    id: 'NOT_SENT',
    name: 'En attente de transmission',
    icon: <Clock style={{ verticalAlign: 'inherit' }} />,
  },
  {
    id: 'SENT',
    name: 'Transmise',
    icon: <ClockFast style={{ verticalAlign: 'inherit' }} />,
  },
  {
    id: 'SUCCESS',
    name: 'Prise en compte',
    icon: <Check style={{ verticalAlign: 'inherit' }} />,
  },
  {
    id: 'ERROR',
    name: 'En erreur',
    icon: <Alert style={{ verticalAlign: 'inherit' }} />,
  },
]);

/**
 * Outlet contract status
 */
export const CONTRACT_STATUSES = getByIdDecorator<ContractStatus>([
  { id: 'ACTIVE', name: 'Actif' },
  { id: 'INACTIVE', name: 'Inactif' },
  { id: 'CREATION', name: 'En constitution' },
]);

/**
 * Questions
 */
export const QUESTIONS = getByIdDecorator<Question>([
  { id: 'BORNE_EXISTANTE', name: 'Borne existante ?', answers: ['YES', 'NO'] },
  { id: 'AJOUT_BORNE', name: 'Ajout de borne ?', answers: ['YES', 'NO'] },
  { id: 'EIPDV_EXISTANT', name: 'EIPDV existant ?', answers: ['YES', 'NO'] },
  { id: 'TRM_EXISTANT', name: 'TRM existant ?', answers: ['YES', 'NO'] },
]);

/**
 * Answers
 */
export const ANSWERS = getByIdDecorator<Answer>([
  { id: 'YES', name: 'Oui' },
  { id: 'NO', name: 'Non' },
]);

export const EQUIPMENT_TYPES = getByIdDecorator<EquipmentType>([
  { id: 'KIT', name: 'Kit' },
  { id: 'UNIT', name: 'Unitaire' },
]);

export const EQUIPMENT_SERIAL_NUMBER_TYPES = getByIdDecorator<EquipmentSerialNumberType>([
  { id: 'ALPHABETIC', name: 'Alphabétique' },
  { id: 'ALPHANUMERIC', name: 'Alphanumérique' },
  { id: 'HEXADECIMAL', name: 'Hexadécimal' },
  { id: 'NUMERIC', name: 'Numérique' },
]);

export const OPERATION_TYPES = getByIdDecorator<OperationType>([
  { id: 'ADD', name: 'Pose' },
  { id: 'MOVE', name: 'Déplacement' },
  { id: 'REMOVE', name: 'Dépose' },
]);

export const COST_STRUCTURE_BUNDLE = 'FORFAIT';

export const COST_STRUCTURE_AFTER_SALES = 'APRES_VENTE';

export const COST_STRUCTURES = getByIdDecorator<CostStructure>([
  { id: COST_STRUCTURE_BUNDLE, name: 'Forfait' },
  { id: COST_STRUCTURE_AFTER_SALES, name: 'Après-vente' },
]);

export const DAYTIME_TYPES = getByIdDecorator<DayTime>([
  { id: 'ANTE_MERIDIEM', name: 'Matin' },
  { id: 'POST_MERIDIEM', name: 'Après midi' },
  { id: 'WHOLE_DAY', name: 'Toute la journée' },
  { id: 'H_07_09', name: 'Entre 7h et 9h' },
  { id: 'H_08_10', name: 'Entre 8h et 10h' },
  { id: 'H_09_11', name: 'Entre 9h et 11h' },
  { id: 'H_10_12', name: 'Entre 10h et 12h' },
  { id: 'H_11_13', name: 'Entre 11h et 13h' },
  { id: 'H_12_14', name: 'Entre 12h et 14h' },
  { id: 'H_13_15', name: 'Entre 13h et 15h' },
  { id: 'H_14_16', name: 'Entre 14h et 16h' },
  { id: 'H_15_17', name: 'Entre 15h et 17h' },
  { id: 'H_16_18', name: 'Entre 16h et 18h' },
  { id: 'H_17_19', name: 'Entre 17h et 19h' },
  { id: 'H_18_20', name: 'Entre 18h et 20h' },
  { id: 'H_19_21', name: 'Entre 19h et 21h' },
  { id: 'H_20_22', name: 'Entre 20h et 22h' },
  { id: 'H_21_23', name: 'Entre 21h et 23h' },
]);

export const EQUIPMENT_STATES = getByIdDecorator<EquipmentState>([
  { id: 'TO_RETURN', name: 'À retourner' },
  { id: 'INSTALLED', name: 'Posé' },
  { id: 'STOCK', name: 'Stock' },
  { id: 'TRANSIT', name: 'Transit' },
  { id: 'PENDING_REPAIR', name: 'En attente de réparation' },
  { id: 'DESTROYED', name: 'Détruit' },
  { id: 'STOLEN', name: 'Volé' },
  { id: 'LOST', name: 'Perdu' },
  { id: 'LOAN', name: 'Prêt' },
  { id: 'GIVEN', name: 'Cédé' },
  { id: 'ON_HOLD', name: 'En attente de déploiement' },
  { id: 'TRANSIT_INSTALLER', name: 'Transit vers installateur' },
  { id: 'TRANSIT_MAINTAINER', name: 'Transit vers mainteneur / constructeur' },
  { id: 'TRANSIT_LOGISTICIAN', name: 'Transit vers logisticien' },
  { id: 'TRANSIT_OUTLET', name: 'Transit vers zone de vente' },
  { id: 'INVENTORY_ERROR', name: "Défaut d'inventaire" },
  { id: 'READY_TO_SEND', name: 'Prêt pour envoi' },
  { id: 'RESERVED_FOR_INTERVENTION', name: 'Réservé pour intervention' },
  { id: 'AVAILABLE', name: 'Disponible' },
]);

export const EQUIPMENT_STATES_RECEPTION = EQUIPMENT_STATES.filter(
  (value) => value.id === 'AVAILABLE' || value.id === 'RESERVED_FOR_INTERVENTION',
);

export const EQUIPMENT_STATES_NEW_MOVE = EQUIPMENT_STATES.filter((value) =>
  ['TO_RETURN', 'INSTALLED', 'TRANSIT', 'READY_TO_SEND', 'RESERVED_FOR_INTERVENTION', 'AVAILABLE', 'LOST'].includes(
    value.id,
  ),
);

export const EQUIPMENT_MOVE_SEND_STATUSES = getByIdDecorator<EquipmentMoveSendStatus>([
  {
    id: 'PENDING',
    name: 'En attente',
    icon: <Clock style={{ verticalAlign: 'inherit' }} />,
  },
  {
    id: 'SUCCESS',
    name: 'Succès',
    icon: <Check color="primary" style={{ verticalAlign: 'inherit' }} />,
  },
  {
    id: 'ERROR',
    name: 'Erreur',
    icon: <AlertCircle color="error" style={{ verticalAlign: 'inherit' }} />,
  },
  {
    id: 'FIXED',
    name: 'Corrigé',
    icon: <CheckCircle color="info" style={{ verticalAlign: 'inherit' }} />,
  },
]);

export const EQUIPMENT_MOVE_SEND_ERRORS = getByIdDecorator<EquipmentMoveSendError>([
  {
    id: 'ERROR',
    name: 'Erreur',
  },
  {
    id: 'UNKNOWN_SERIAL_NUMBER',
    name: 'N° de série inconnu',
  },
  {
    id: 'UNKNOWN_OUTLET',
    name: 'Lieu inconnu',
  },
]);

export const USER_STATUSES = getByIdDecorator<UserStatus>([
  { id: 'ACTIVE', name: 'Actif', icon: <Account color="primary" style={{ verticalAlign: 'inherit' }} /> },
  { id: 'INACTIVE', name: 'Inactif', icon: <AccountOutline color="disabled" style={{ verticalAlign: 'inherit' }} /> },
  { id: 'BLOCKED', name: 'Bloqué', icon: <Cancel color="error" style={{ verticalAlign: 'inherit' }} /> },
]);

export const FDD_STATUSES = getByIdDecorator<FDDStatus>([
  { id: '_00_DRAFT', name: 'En cours de saisie' },
  { id: '_10_AWAITING_DA_VALIDATION', name: 'En attente de validation MDZ' },
  { id: '_11_AWAITING_BD_VALIDATION', name: 'En attente de validation RDD' },
  { id: '_12_AWAITING_DAF_VALIDATION', name: 'En attente de validation DAF' },
  { id: '_13_AWAITING_DR_VALIDATION', name: 'En attente de validation DR' },
  { id: '_14_AWAITING_CI_VALIDATION', name: 'En attente de validation CI' },
  { id: '_20_IN_PROGRESS', name: 'Validé - En cours' },
  { id: '_30_CLOSED', name: 'Clôturé' },
  { id: '_40_CANCELED', name: 'Annulé' },
]);
export const validatingFDDStatuses: FDDStatusId[] = [
  '_10_AWAITING_DA_VALIDATION',
  '_11_AWAITING_BD_VALIDATION',
  '_12_AWAITING_DAF_VALIDATION',
  '_13_AWAITING_DR_VALIDATION',
  '_14_AWAITING_CI_VALIDATION',
];
export const fddLockedPaymentStatuses: FDDStatusId[] = ['_13_AWAITING_DR_VALIDATION', '_14_AWAITING_CI_VALIDATION'];

export const sortableRowProps = {
  lockAxis: 'y',
  helperClass: 'dragging-row',
  pressDelay: isTouchScreen ? 200 : 0,
  distance: isTouchScreen ? 0 : 20,
  useWindowAsScrollContainer: true,
};

export const OUTLET_TYPES = getByIdDecorator<OutletType>([
  { id: 'OUTLET', name: 'Point de vente', hasAgency: true, hasArea: true, hasContract: true },
  { id: 'AGENCY', name: 'Zone de vente', hasAgency: true, hasArea: false, hasContract: false },
  { id: 'STORAGE', name: 'Lieu de stockage', hasAgency: false, hasArea: false, hasContract: false },
  { id: 'OLD_AGENCY', name: 'Ancienne agence', hasAgency: false, hasArea: false, hasContract: false },
]);

export const FDD_PRODUCTS = getByIdDecorator<FDDProduct>([
  { id: 'ALL', name: 'Tous' },
  { id: 'SIMPLE', name: 'Simple' },
  { id: 'COUPLE', name: 'Couplé' },
  { id: 'TIERCE', name: 'Tiercé' },
  { id: 'TRIO', name: 'Trio' },
  { id: 'QUARTE_PLUS', name: 'Quarté+' },
  { id: 'QUINTE_PLUS', name: 'Quinté+' },
  { id: 'MULTI', name: 'Multi' },
  { id: 'PICK5', name: 'Pick5' },
  { id: 'TWO_FOR_FOUR', name: '2sur4' },
  { id: 'SUPER4', name: 'Super4' },
]);

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { usePageTitle } from '../../../utils/page-title';
import { Card } from '@mui/material';
import StockEquipmentOutletsTable from '../../stock-equipments/StockEquipmentOutletsTable';

const StocksPage = () => {
  usePageTitle('Stocks prestataires');

  return (
    <Card>
      <StockEquipmentOutletsTable />
    </Card>
  );
};

export default securedPage(roles.stockEquipments.view.code)(StocksPage);

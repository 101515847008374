import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { changeActivationStatus } from '../../actions/UserActions';
import { AccountReactivate, AccountRemove } from 'mdi-material-ui';

class UserActivationDialog extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  state = {
    opened: false,
  };

  onOpenToggle = () => {
    this.setState(({ opened }) => ({
      opened: !opened,
    }));
  };

  onConfirm = () => {
    this.setState({
      opened: false,
    });
    this.props.dispatch(changeActivationStatus(this.props.id, !this.props.active));
  };

  render() {
    const actionLabel = this.props.active ? 'Désactiver' : 'Activer';
    const actionIcon = this.props.active ? <AccountRemove /> : <AccountReactivate />;
    return (
      <div>
        <Button variant="contained" onClick={this.onOpenToggle} startIcon={actionIcon}>
          {actionLabel}
        </Button>
        <Dialog open={this.state.opened} onClose={this.onOpenToggle}>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir {actionLabel.toLowerCase()} l'utilisateur {this.props.email} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onOpenToggle}>Annuler</Button>
            <Button color="secondary" onClick={this.onConfirm}>
              {actionLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UserActivationDialog;

import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Download } from 'mdi-material-ui';

import { paginationToParams } from '../../utils/paging';
import { downloadUrl } from '../../actions/DownloadActions';
import { equipmentMoveExportUrl } from '../../actions/EquipmentMoveActions';

const styles = (theme) => ({
  buttonLabel: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

class EquipmentMoveExport extends Component {
  render() {
    const { pagination, dispatch, classes } = this.props;
    const params = paginationToParams(pagination);
    delete params.page;
    delete params.size;

    let urlParam = '';
    if (params.filter) {
      params.filter.forEach((filter, idx) => {
        urlParam += `${idx === 0 ? '?' : '&'}filter%5B%5D=${encodeURIComponent(filter)}`;
      });
    }

    const url = equipmentMoveExportUrl + urlParam;

    return (
      !!pagination.totalElements && (
        <Button onClick={() => dispatch(downloadUrl(url, 'Mouvements.csv'))}>
          <Download />
          <span className={classes.buttonLabel}>Exporter les mouvements</span>
        </Button>
      )
    );
  }
}

const stateToProps = ({ equipmentMoves: { pagination } }) => ({
  pagination,
});

export default compose(withStyles(styles), connect(stateToProps))(EquipmentMoveExport);

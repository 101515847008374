import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { EquipmentStateId, StockBatchElement } from '../../model/model';
import { Button, Card, CardContent, Grid, Table, TableBody, Typography } from '@mui/material';
import Formsy from 'formsy-react';
import { AutocompleteField, FormActions, TextField } from '../utils';
import { v4 as uuid } from 'uuid';
import { EQUIPMENT_STATES_RECEPTION } from '../../constants/AppConstants';
import { addEquipmentsToStock } from '../../actions/StockEquipmentActions';
import { useAppDispatch } from '../../hooks';
import StockEquipmentRow from './StockEquipmentRow';
import { useNavigate } from 'react-router-dom';
import { loadActiveEquipmentsWithSerialNumber } from '../../actions/settings/EquipmentActions';
import { addGlobalError } from '../../actions/SnackbarActions';
import { IModel, IResetModel, IUpdateInputsWithError } from 'formsy-react/src/interfaces';
import { Check } from 'mdi-material-ui';

type StockEquipmentReceptionFieldProps = {
  stockOutletCode: string;
};

const errorMessages = {
  defaultState: {
    isDefaultRequiredValue: 'Veuillez sélectionner un statut par défaut',
    isExisty: 'Veuillez sélectionner un statut par défaut',
  },
  interventionNumber: {
    isDefaultRequiredValue: "Veuillez saisir un numéro d'intervention",
    isExisty: "Veuillez saisir un numéro d'intervention",
  },
};

const StockEquipmentReception = ({ stockOutletCode }: StockEquipmentReceptionFieldProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadActiveEquipmentsWithSerialNumber());
  }, [dispatch]);

  const [defaultState, setDefaultState] = useState<EquipmentStateId>('RESERVED_FOR_INTERVENTION');
  const [interventionNumber, setInterventionNumber] = useState('');

  const getEmptyItem = (): StockBatchElement => {
    return {
      id: uuid(),
      serialNumber: null,
      equipmentName: null,
      equipmentCode: null,
      state: defaultState,
    };
  };

  const [items, setItems] = useState([getEmptyItem()]);

  const hasReserved = useMemo(
    () =>
      defaultState === 'RESERVED_FOR_INTERVENTION' || items.some((item) => item.state === 'RESERVED_FOR_INTERVENTION'),
    [defaultState, items],
  );

  const onDefaultStateChange = (state: EquipmentStateId) => {
    setDefaultState(state);
    setItems(
      items.map((item) =>
        item.serialNumber
          ? item
          : {
              ...item,
              state,
            },
      ),
    );
  };

  const onInterventionNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInterventionNumber(event.target.value);
  };

  const onSave = (_model: IModel, _resetModel: IResetModel, updateInputsWithError: IUpdateInputsWithError) => {
    const itemsToSave = items
      .filter((item) => item.serialNumber)
      .map((item) =>
        item.state === 'RESERVED_FOR_INTERVENTION'
          ? {
              ...item,
              interventionNumber,
            }
          : item,
      );
    if (itemsToSave?.length) {
      dispatch(addEquipmentsToStock(stockOutletCode, itemsToSave, updateInputsWithError));
    } else {
      dispatch(addGlobalError('Veuillez renseigner au moins un matériel à réceptionner.'));
    }
  };

  const onRemoveItem = (id) => {
    let newItems = items.filter((item) => item.id !== id);
    if (!newItems.length) {
      newItems = [getEmptyItem()];
    }
    setItems(newItems);
  };

  const onEquipmentChange = (stockEquipment: StockBatchElement) => {
    let newItems: StockBatchElement[] = items.map((item) => {
      return item.id === stockEquipment.id
        ? {
            ...item,
            serialNumber: stockEquipment?.serialNumber ?? null,
            equipmentCode: stockEquipment?.equipmentCode ?? null,
            equipmentName: stockEquipment?.equipmentName ?? null,
            state: stockEquipment?.state ?? null,
            interventionNumber: stockEquipment?.state === 'RESERVED_FOR_INTERVENTION' ? interventionNumber : null,
          }
        : item;
    });

    if (newItems.every((item) => item.serialNumber)) {
      setItems([...newItems, getEmptyItem()]);
    } else {
      setItems(newItems);
    }
  };

  return (
    <Formsy onValidSubmit={onSave} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Réception de matériel</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <AutocompleteField
                    options={EQUIPMENT_STATES_RECEPTION}
                    label="Statut par défaut"
                    fullWidth
                    name="state"
                    onChange={onDefaultStateChange}
                    value={defaultState}
                    required
                    validations="isExisty"
                    validationErrors={errorMessages.defaultState}
                    clearIcon={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {hasReserved && (
                    <TextField
                      type="number"
                      label="Numéro d'intervention"
                      name="interventionNumber"
                      fullWidth
                      value={interventionNumber}
                      onChange={onInterventionNumberChange}
                      required
                      validations="isExisty"
                      validationErrors={errorMessages.interventionNumber}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item container xs={12} marginTop={2}>
          <Card>
            <Table>
              <TableBody>
                {items.map((item: StockBatchElement) => (
                  <StockEquipmentRow
                    key={item.id}
                    stockBatchElement={item}
                    onRemoveItem={onRemoveItem}
                    onEquipmentChange={onEquipmentChange}
                    handleState={true}
                  />
                ))}
              </TableBody>
            </Table>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <FormActions
              leftActions={[]}
              rightActions={[
                <Button key="cancel" variant="contained" onClick={() => navigate(-1)}>
                  Annuler
                </Button>,
                <Button key="save" variant="contained" color="primary" type="submit" startIcon={<Check />}>
                  Confirmer la réception
                </Button>,
              ]}
            />
          </Card>
        </Grid>
      </Grid>
    </Formsy>
  );
};

export default StockEquipmentReception;

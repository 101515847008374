import { DecoratedArray, getByIdDecorator, STOCK_REMOVAL_TARGETS_LOADED } from '../constants/AppConstants';
import { StockRemovalTarget } from '../model/model';
import { AnyAction } from 'redux';

export type StockRemovalTargetState = DecoratedArray<StockRemovalTarget>;

const initialState: StockRemovalTargetState = getByIdDecorator([]);

export default function (state: StockRemovalTargetState = initialState, action: AnyAction): StockRemovalTargetState {
  switch (action.type) {
    case STOCK_REMOVAL_TARGETS_LOADED:
      return getByIdDecorator([...action.stockRemovalTargets]);
    default:
      return state;
  }
}

import { useEffect, useState } from 'react';
import { EquipmentRemovalBatch, StockBatchElement } from '../../model/model';
import { Button, Card, CardContent, Grid, Table, TableBody, Typography } from '@mui/material';
import Formsy from 'formsy-react';
import { FormActions, LoadingMessage } from '../utils';
import { v4 as uuid } from 'uuid';
import { urls } from '../../constants/AppConstants';
import { deleteRemovalBatch, prepareRemovalEquipment } from '../../actions/StockEquipmentActions';
import { useAppDispatch } from '../../hooks';
import StockEquipmentRow from './StockEquipmentRow';
import { loadActiveEquipmentsWithSerialNumber } from '../../actions/settings/EquipmentActions';
import { useNavigate } from 'react-router-dom';
import { api, convert404ToNull, ignore401 } from '../../services/RestService';
import { StockRemovalTargetAutocompleteField } from '../commons/autocompleteFields';
import { ContentSave, Delete, Send } from 'mdi-material-ui';

type StockEquipmentRemovalPreparationFieldProps = {
  stockOutletCode: string;
  batchId?: string;
};

const errorMessages = {
  targetStockCode: {
    isDefaultRequiredValue: 'Veuillez sélectionner un destinataire',
    isExisty: 'Veuillez sélectionner un destinataire',
  },
};

const loadBatch = async (stockOutletCode: string, batchId: string): Promise<EquipmentRemovalBatch | null> => {
  try {
    const response = await api.custom(`${urls.stockEquipments}/${stockOutletCode}/removal/${batchId}`).get();
    return response.body(false);
  } catch (error) {
    return convert404ToNull(error).catch((error) => ignore401(error));
  }
};

const StockEquipmentRemovalPreparation = ({ stockOutletCode, batchId }: StockEquipmentRemovalPreparationFieldProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadActiveEquipmentsWithSerialNumber());
  }, [dispatch]);

  const [targetStockCode, setTargetStockCode] = useState<string>();
  const onTargetStockCodeChange = (targetStockCode: string) => {
    setTargetStockCode(targetStockCode);
  };

  const getEmptyItem = (): StockBatchElement => {
    return {
      id: uuid(),
      serialNumber: null,
      equipmentName: null,
      equipmentCode: null,
    };
  };
  const [items, setItems] = useState([getEmptyItem()]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  useEffect(() => {
    if (batchId) {
      (async () => {
        const batch = await loadBatch(stockOutletCode, batchId);
        setLoading(false);
        if (batch) {
          setTargetStockCode(batch.targetStockCode);
          setItems([...batch.stockEquipments.map((eq) => ({ id: uuid(), ...eq })), getEmptyItem()]);
        } else {
          setError('Préparation introuvable');
        }
      })();
    } else {
      setLoading(false);
      setTargetStockCode(undefined);
      setItems([getEmptyItem()]);
    }
  }, [batchId, stockOutletCode]);

  if (loading || error) {
    return (
      <LoadingMessage loading={loading} serverError={error}>
        Préparation sortie stock
      </LoadingMessage>
    );
  }

  const onSave = () => {
    const itemsToSave: StockBatchElement[] = items.filter((item) => item.serialNumber);
    if (itemsToSave) {
      dispatch(prepareRemovalEquipment(stockOutletCode, itemsToSave, targetStockCode, batchId, false));
    }
  };

  const onSend = () => {
    const itemsToSave: StockBatchElement[] = items.filter((item) => item.serialNumber);
    if (itemsToSave) {
      dispatch(prepareRemovalEquipment(stockOutletCode, itemsToSave, targetStockCode, batchId, true));
    }
  };

  const onRemoveItem = (id: string) => {
    let newItems = items.filter((item) => item.id !== id);
    if (!newItems?.length) {
      newItems = [getEmptyItem()];
    }
    setItems(newItems);
  };

  const onEquipmentChange = (stockEquipment: StockBatchElement) => {
    let newItems: StockBatchElement[] = items.map((item) => {
      return item.id === stockEquipment.id
        ? {
            ...item,
            serialNumber: stockEquipment?.serialNumber ?? null,
            equipmentCode: stockEquipment?.equipmentCode ?? null,
            equipmentName: stockEquipment?.equipmentName ?? null,
          }
        : item;
    });

    if (newItems.every((item) => item.serialNumber)) {
      setItems([...newItems, getEmptyItem()]);
    } else {
      setItems(newItems);
    }
  };

  const onDeletePreparation = () => {
    dispatch(deleteRemovalBatch(stockOutletCode, batchId));
  };

  return (
    <Formsy onValidSubmit={onSave} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Préparation sortie stock</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StockRemovalTargetAutocompleteField
                    label="Destinataire"
                    fullWidth
                    name="targetStockCode"
                    onChange={onTargetStockCodeChange}
                    value={targetStockCode}
                    validations="isExisty"
                    validationErrors={errorMessages.targetStockCode}
                    clearIcon={false}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Table>
              <TableBody>
                {items.map((item: StockBatchElement) => (
                  <StockEquipmentRow
                    key={item.id}
                    stockBatchElement={item}
                    onRemoveItem={onRemoveItem}
                    onEquipmentChange={onEquipmentChange}
                    handleState={false}
                  />
                ))}
              </TableBody>
            </Table>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <FormActions
              leftActions={
                batchId && !loading ? (
                  <Button
                    key="delete"
                    variant="contained"
                    color="error"
                    onClick={onDeletePreparation}
                    startIcon={<Delete />}
                  >
                    Supprimer la préparation
                  </Button>
                ) : null
              }
              rightActions={
                <>
                  <Button key="cancel" variant="contained" onClick={() => navigate(-1)}>
                    Annuler
                  </Button>
                  <Button key="send" variant="contained" onClick={onSend} startIcon={<Send />}>
                    Enregistrer et envoyer
                  </Button>
                  <Button key="save" variant="contained" color="primary" type="submit" startIcon={<ContentSave />}>
                    Enregistrer la préparation
                  </Button>
                </>
              }
            />
          </Card>
        </Grid>
      </Grid>
    </Formsy>
  );
};

export default StockEquipmentRemovalPreparation;

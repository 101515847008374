import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardActions, CardContent, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Formsy from 'formsy-react';
import dayjs from 'dayjs';
import { ContentSave } from 'mdi-material-ui';

import { addGlobalError } from '../../actions/SnackbarActions';
import { AutocompleteField, DatePickerField, LabelValue } from '../utils';
import { hasRole } from '../../services/SecurityService';
import roles from '../../constants/roles';
import { saveEquipmentMove } from '../../actions/EquipmentMoveActions';
import { EQUIPMENT_STATES_NEW_MOVE } from '../../constants/AppConstants';
import { addOutletExistsValidationRule } from '../../utils/validation-rules';
import { OutletAutocompleteField } from '../commons/autocompleteFields';
import SerialNumberField from '../utils/SerialNumberField';

const errorMessages = {
  outlet: {
    isDefaultRequiredValue: 'Le lieu est obligatoire',
    matchRegexp: 'Le code lieu est constitué de chiffres',
    maxLength: 'Le code lieu fait 6 caractères maximum',
    outletExists: 'Le lieu doit exister',
  },
  serialNumber: {
    isDefaultRequiredValue: 'Veuillez saisir un numéro de série',
    isExisty: 'Veuillez saisir un numéro de série',
  },
  date: {
    isDefaultRequiredValue: 'Veuillez sélectionner une date',
    isExisty: 'Veuillez sélectionner une date',
  },
  state: {
    isDefaultRequiredValue: 'Veuillez sélectionner un état',
    isExisty: 'Veuillez sélectionner un état',
  },
};

class NewEquipmentMove extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      outletCode: '',
      serialNumber: '',
      date: dayjs(),
      state: null,
      outlet: null,
      equipmentName: null,
      equipmentCode: null,
    };

    addOutletExistsValidationRule();
  }

  onOutletChange = (outletCode, outlet) => {
    this.setState({
      outletCode,
      outlet,
    });
  };

  onSerialNumberChange = (serialNumber) => this.setState({ serialNumber });

  onEquipmentChange = (stockEquipment) => {
    this.setState({
      equipmentName: stockEquipment?.equipmentName ?? null,
      equipmentCode: stockEquipment?.equipmentCode ?? null,
    });
  };

  onDateChange = (date) => this.setState({ date });

  onStateChange = (state) => this.setState({ state });

  onSave = () => {
    const { onSave } = this.props;
    const {
      outlet: { id: outletId },
      date,
      serialNumber,
      equipmentName,
      equipmentCode,
      state,
    } = this.state;
    onSave({
      outletId,
      date: dayjs(date).format('YYYY-MM-DD'),
      serialNumber,
      equipmentName,
      equipmentCode,
      state,
    });
  };

  onInvalidSubmit = () => this.props.onAddGlobalError('Le formulaire contient des erreurs');

  render() {
    const { currentUser } = this.props;
    const { outletCode, serialNumber, date, state, outlet } = this.state;
    return (
      <Formsy onValidSubmit={this.onSave} onInvalidSubmit={this.onInvalidSubmit} noValidate ref={this.form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1">Déclarer un matériel dans un lieu</Typography>
          </Grid>

          <Grid item xs={12} sm={6} container>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Lieu</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <OutletAutocompleteField
                      label="Code"
                      fullWidth
                      required
                      name="outletCode"
                      value={outletCode}
                      onChange={this.onOutletChange}
                      getOptionValue={({ code }) => code}
                      validations={{
                        outletExists: outlet,
                      }}
                      validationErrors={errorMessages.outlet}
                    />
                  </Grid>
                  {outlet && (
                    <>
                      <Grid item xs={12}>
                        <LabelValue label="Enseigne">
                          {hasRole(currentUser, roles.outlet.view.code) ? (
                            <Link component={RouterLink} to={`/outlets/${outlet.id}`}>
                              {outlet.name}
                            </Link>
                          ) : (
                            outlet.name
                          )}
                        </LabelValue>
                      </Grid>
                      <Grid item xs={12}>
                        <LabelValue label="Région">{outlet.region?.name}</LabelValue>
                      </Grid>
                      <Grid item xs={12}>
                        <LabelValue label="Zone de vente">{outlet.agency?.name}</LabelValue>
                      </Grid>
                      {outlet.area?.name && (
                        <Grid item xs={12}>
                          <LabelValue label="Secteur">{outlet.area.name}</LabelValue>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} container>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Informations générales</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DatePickerField
                      label="Date du mouvement"
                      fullWidth
                      name="date"
                      required
                      validations="isExisty"
                      validationErrors={errorMessages.date}
                      value={date}
                      onChange={this.onDateChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SerialNumberField
                      label="Numéro de série"
                      fullWidth
                      name="serialNumber"
                      required
                      validations="isExisty"
                      validationErrors={errorMessages.serialNumber}
                      value={serialNumber}
                      onChange={this.onSerialNumberChange}
                      onEquipmentLoaded={this.onEquipmentChange}
                    />
                  </Grid>
                  {this.state.equipmentName && (
                    <Grid item xs={12}>
                      <LabelValue label="Modèle du matériel">{this.state.equipmentName}</LabelValue>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <AutocompleteField
                      options={EQUIPMENT_STATES_NEW_MOVE}
                      label="État du matériel"
                      fullWidth
                      name="state"
                      value={state}
                      required
                      validations="isExisty"
                      validationErrors={errorMessages.state}
                      onChange={this.onStateChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.form.current.submit()}
                  startIcon={<ContentSave />}
                >
                  Enregistrer
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Formsy>
    );
  }
}

const stateToProps = ({ currentUser }) => ({
  currentUser,
});

const dispatchToProps = {
  onAddGlobalError: addGlobalError,
  onSave: saveEquipmentMove,
};

export default connect(stateToProps, dispatchToProps)(NewEquipmentMove);

import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip } from '@mui/material';
import { FilePlus } from 'mdi-material-ui';

class AddToDemands extends React.Component {
  static propTypes = {
    icon: PropTypes.bool,
    elementName: PropTypes.string,
    onAddToDemands: PropTypes.func,
  };

  state = {
    dialogOpened: false,
  };

  onOpenDialog = () => {
    this.setState({ dialogOpened: true });
  };

  onCloseDialog = () => {
    this.setState({ dialogOpened: false });
  };

  onConfirm = () => {
    const { onAddToDemands } = this.props;
    onAddToDemands();
    this.onCloseDialog();
  };

  render() {
    // noinspection JSUnusedLocalSymbols
    const { icon, elementName, onAddToDemands, ...otherProps } = this.props;
    const { dialogOpened } = this.state;
    return (
      <div {...otherProps}>
        {icon ? (
          <Tooltip title="Ajouter aux demandes qui n'ont pas la prestation">
            <IconButton onClick={this.onOpenDialog} size="large">
              <FilePlus />
            </IconButton>
          </Tooltip>
        ) : (
          <Button variant="contained" onClick={this.onOpenDialog} startIcon={<FilePlus />}>
            Ajouter aux demandes existantes
          </Button>
        )}
        <Dialog open={dialogOpened} onClose={this.onCloseDialog}>
          <DialogContent>
            <DialogContentText>Ajouter {elementName} aux demandes qui ne l'ont pas encore ?</DialogContentText>
            <DialogContentText>
              Attention, la modification d'un grand nombre de demandes peut ralentir l'ensemble de l'application pendant
              plusieurs minutes. Effectuez cette opération de préférence en dehors des heures les plus chargées.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCloseDialog}>Annuler</Button>
            <Button onClick={this.onConfirm} color="primary">
              Ajouter aux demandes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AddToDemands;
